import { useEffect, useState } from 'react'
import { makeStyles, Box, Tab, Tabs, Theme } from '@material-ui/core'
import * as navigator from '../tools/Navigator'

interface TabPanelProp {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  }
}));

function TabPanel(prop: TabPanelProp) {
  const { children, value, index, ...other } = prop;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export interface TabbedPanelTabProp {
  label: string,
  icon?: string | React.ReactElement,
  child: React.ReactNode
}

export interface TabbedPanelProp {
  tabs: TabbedPanelTabProp[]
}

export function TabbedPanel(prop: TabbedPanelProp) {
  const [tabValue, setTabValue] = useState(navigator.getTabIndex() ?? 0)
  const classes = useStyles()

  const handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    setTabValue(value)
  }

  useEffect(() => {
    navigator.replacePartially(/\/tab-[0-9]+|\/?$/, `/tab-${tabValue}`)
  }, [tabValue])

  return (
    <div className={classes.root}>
      <Tabs value={tabValue} onChange={handleTabChange} textColor='primary' indicatorColor='primary' centered>
        {prop.tabs.map((tab, index) => <Tab label={tab.label} icon={tab.icon} key={index}/>)}
      </Tabs>
      {prop.tabs.map((tab, index) => <TabPanel value={tabValue} index={index} key={index}>{tab.child}</TabPanel>)}
    </div>
  )
}
