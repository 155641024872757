import * as msal from "@azure/msal-browser"
import { Opt } from "./Utils"

interface AadEnvironment {
  domainHint?: string,
  scopes: string[],
  msalConfig: msal.Configuration,
  redirectUri: string
}

const envs: Record<string, AadEnvironment> = {
  'iss-aad': {
    domainHint: 'isstoday.onmicrosoft.com',
    msalConfig: {
      auth: {
        clientId: '8b34c09e-44ed-4ee2-8d36-fcae694a20b2',
        authority: 'https://login.microsoftonline.com/0e76efa8-a57a-46d8-a3f4-49f722a82f45',
      },
      cache: {
        cacheLocation: "localStorage",
      }
    },
    scopes: ['User.Read'],
    redirectUri: '/'
  },
  'dev-iss-aad': {
    domainHint: 'isstoday.onmicrosoft.com',
    msalConfig: {
      auth: {
        clientId: '4cd0e49f-0d6a-44cc-9982-e589ace5d566',
        authority: 'https://login.microsoftonline.com/94a11c53-9fb9-4e79-bbfa-3cdc5164e57d',
      },
      cache: {
        cacheLocation: "localStorage",
      }
    },
    scopes: ['User.Read'],
    redirectUri: '/'
  },
  'dev-aad': {
    domainHint: 'issfinlandcloud-dev.solitaonline.fi',
    msalConfig: {
      auth: {
        clientId: 'ab6a4312-c12e-445e-b0f2-68e84984a1a0',
        authority: 'https://login.microsoftonline.com/65ffb584-e13a-4a90-95fa-bdc755b3b7cf',
      },
      cache: {
        cacheLocation: "localStorage",
      }
    },
    scopes: ['User.Read'],
    redirectUri: '/'
  }
}
const env = envs['iss-aad']
const msalInstance = new msal.PublicClientApplication(env.msalConfig)

export function getAccount(): msal.AccountInfo | undefined {
  const all = msalInstance.getAllAccounts()
  return all.length > 0 ? all[0] : undefined
}

function getAccountInfo(acc?: msal.AccountInfo) {
  return acc === undefined ? '-' : `${acc.name ?? '?'} (${acc.username})`
}

export function isSignedIn() {
  return getAccount() !== undefined
}

export async function signIn(): Promise<msal.AuthenticationResult> {

  const loginResponse = await msalInstance.loginPopup({
    scopes: env.scopes,
    domainHint: env.domainHint,
    //redirectUri: ensureTrailingSlash(navigator.appHomepage ?? '')
    //redirectUri: '/' // TODO why doesn't '/kohdekansiot/' work even if the same is configured in AAD - does it need to be an absolute url?
    // MS does recommend though to use a blank or non-authenticated URI: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirecturi-considerations
    redirectUri: env.redirectUri
  })

  return loginResponse
}

export async function signOut() {
  const account = getAccount()
  console.log(`sign out ${getAccountInfo(account)}`)

  // The docs say that 'postLogoutRedirectUri' is required, but the logout seems to function fine:
  // after it has done its business, a post logout page is shown:
  // - it says "... You have successfully signed out"
  // - page URL is https://sts.iss-today.fi/adfs/oauth2/logout?post_logout_redirect_uri=https://sts.iss-today.fi/adfs&client-request-id=...
  // Also, specifying 'postLogoutRedirectUri' doesn't seem to do anything (tried URLs relative to the app and
  // an URL in the ADFS server domain).
  let logoutRequest = {
    account: account
  }

  try {
    await msalInstance.logoutPopup(logoutRequest)
  } catch (err) {
    console.log(`error signing out: ${err}`)
  }
}

// TODO: Clean up after real ISS AAD implementation if this is still not needed.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ensureTrailingSlash(path: string) {
  return path.endsWith('/') ? path : path + '/'
}

export async function acquireToken(interactive: Opt<boolean> = null, forceRefresh = false): Promise<string> {
  const account = getAccount()

  if (!account) {
    throw new Error('No account')
  }

  console.log(`Acquire token for ${getAccountInfo(account)} (interactive=${interactive}, forceRefresh=${forceRefresh})`)

  // Without specifying at least 1 scope, acquireTokenSilent will always
  // make a token request or two before returning the (already known) token.
  const tokenRequest = {
    account: account,
    forceRefresh: forceRefresh,
    scopes: env.scopes,
    redirectUri: env.redirectUri
  }

  try {
    let token: msal.AuthenticationResult

    if (interactive === true) {
      console.log(`Retry acquire token for ${getAccountInfo(account)}`)
      token = await msalInstance.acquireTokenPopup(tokenRequest)
    } else {
      token = await msalInstance.acquireTokenSilent(tokenRequest)
      // Token claims object is not typed, so cast here to avoid lint errors on accessing nonexistent properties.
      const tokenClaims = token.idTokenClaims as {exp: number}

      if (!forceRefresh && tokenClaims !== undefined && Number.isInteger(tokenClaims['exp'])) {
        let idTokenExpires = tokenClaims['exp'] * 1000; // Claim value is in seconds since epoch.
        let expiresMargin = 5 * 60 * 1000; // Bit of margin to allow slightly out-of-sync device clocks.
        let expiresIn = idTokenExpires - Date.now();

        if (expiresIn < expiresMargin) {
          console.log('Id-token has expired or is about to soon - force refresh it')
          return acquireToken(false, true);
        }
      }
    }

    return token.idToken
  } catch (err) {
    // Value is null by default -> allows retrying if silent method fails. Specifying value "false" can be used to disable retry.
    if (interactive == null && err instanceof msal.InteractionRequiredAuthError) {
      console.log(`Retry interactively ${err}`)
      return acquireToken(true, forceRefresh)

    } else {
      console.log(`Failed to acquire token: ${err}`)
      throw err
    }
  }
}
