import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { DirectionsOutlined, LocationCity } from '@material-ui/icons'
import { useSnackbar } from 'notistack'

import * as api from '../tools/API'
import { Site } from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import { getErrorMessage } from '../tools/Utils'
import { DetailsCard } from './DetailsCard'
import { TIButton } from './SmallComponents'
import { LabeledItem } from './LabeledItem'

export function SiteInfoCard({site}: {site?: Site}) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [description, setDescription] = useState('')

  useEffect(() => { setDescription(site?.description ?? '') }, [site?.description])

  async function saveDescription(description: string) {
    try {
      await api.updateSiteDescription(site!.id, {description: description})
      site!.description = description
      setDescription(description)
    } catch (err) {
      console.log(`Failed to save description: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errSave', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
  }

  return (
    <DetailsCard cardId='siteInfo' title={t('siteInfoTitle')} icon={<LocationCity/>}>
      { site &&
      <Grid container direction='column' spacing={1}>
        <LabeledItem label={t('siteInfoLabelExtId')} value={site.externalId?.toString() ?? 'N/A'} />
        <LabeledItem label={t('siteInfoLabelName')} value={site.name} />
        <LabeledItem
          label={t('siteInfoLabelAddress')}
          value={formatAddress(site)}
          action={
            <TIButton
              edge='end'
              href={getMapsLink(site)}
              target="_blank"
              rel="noopener"
              icon={<DirectionsOutlined/>}
              tooltipKey="siteInfoLabelAddressTooltip"
            />
          }
        />
        <LabeledItem
          label={t('siteInfoLabelDescription')}
          value={description}
          onSave={description => saveDescription(description)}
          maxLength={3000}
        />
      </Grid>
      }
    </DetailsCard>
  )
}

function getMapsLink(site: Site) {
  const address = formatAddress(site)
  return `https://www.google.com/maps/search/?api=1&query=${address}`
}

function formatAddress(site: Site) {
  return `${site.streetAddress ? site.streetAddress+',' : ''} ${site.postCode ?? ''} ${site.city ?? ''}`
}
