import * as api from './API'
import { handleDownload } from './Utils'

export interface DownloadAttachmentButtonProp {
  path: string
}

export async function downloadAttachmentWithUid(p: DownloadAttachmentButtonProp):Promise<number> {

  const uid = p.path.replace("/site/file/", "");
  let targetId = null;

  try {
    const imgMetaData = await api.getSiteFileDataWithUid(uid);
    targetId = imgMetaData.targetId;
    const blob = await api.getSiteFileWithUid(uid);
    handleDownload(blob, imgMetaData.filename, true);
  } catch (err) {
    console.error(`Error downloading file: ${JSON.stringify(err)}`);
  }
  return (targetId as number);
}