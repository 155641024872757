import ReactMarkDown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks'
import { makeStyles } from '@material-ui/core'

export interface MarkdownTextProperties {
  text: string
}

export function MarkdownText(p: MarkdownTextProperties) {
  const useStyles = makeStyles(theme => (
    {
      default: {
        fontSize: '0.875rem', // By default markdown field would use user-agent styles, causing variation between browsers in font-size
        paddingLeft: '16px'
      }
    }
  ))
  const classes = useStyles()
  const {text, ...rest} = p
  return (
    <ReactMarkDown
      children={linkifyEmailAddressesAndTelephoneNumbers(text)}
      remarkPlugins={[remarkBreaks, remarkGfm]}
      className={classes.default}

      {...rest}
    />
  )
}

function linkifyEmailAddressesAndTelephoneNumbers(str: string) {
  // The first group is "start of line or empty space" instead of a word boundary so that markdown links (e.g. "(name)[mailto:user@host.invalid]") would get ignored.
  // Alternative could be to do similar processing in backend, but the advantage of this is that the same data as user entered is shown when modifying the text
  // later - markdown could look confusing to a user.
  return str
    .replace(/(^|\s+)([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})\b/gi, '$1[$2](mailto:$2)')
    .replace(/(^|\s+)(\+?\d{7,13})\b/g, '$1[$2](tel:$2)')
}
