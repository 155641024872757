import { DateTime } from "luxon";
import { CalendarEvent, CalendarEventFormModel } from './API'
import { blankAsUndefined, Opt, truncateToDate, LocalDate, omit } from './Utils'

export function convertToEntity(data: CalendarEventFormModel, date: Opt<LocalDate>, startTime: Opt<string>, endTime: Opt<string>, isAllDayEvent: Opt<boolean>): CalendarEvent {
  // Trim strings (validation has taken care of not having blank strings for mandatory fields).
  const result = Object.assign({
    subject: data.subject.trim(),
    location: data.location.trim(),
    description: blankAsUndefined(data.description),
    eventStart: constructDateTimeFromParts(date!, startTime, '00:00'),
    eventEnd: constructDateTimeFromParts(date!, endTime, '23:59'),
    isAllDayEvent: isAllDayEvent || (startTime == null && endTime == null),
  }, omit(data, ['eventDate', 'startTime', 'endTime', 'isAllDayEvent']))
  return result
}

export function convertToFormModel(data: CalendarEvent): CalendarEventFormModel {
  const result = Object.assign({
    eventDate: truncateToDate(data.eventStart),
    startTime: data.isAllDayEvent ? '' : data.eventStart.toFormat('HH:mm'),
    endTime: data.isAllDayEvent ? '' : data.eventEnd.toFormat('HH:mm')
  }, omit(data, ['eventStart', 'eventEnd']))
  return result
}

export function compareEvents(a: CalendarEvent, b: CalendarEvent) {
  // Backend only compares by start instant so this could result in a different order but at least this is stable.
  const dStart = a.eventStart.toMillis() - b.eventStart.toMillis()
  if (dStart !== 0) {
    return dStart
  }
  return a.eventEnd.toMillis() - b.eventEnd.toMillis()
}

function constructDateTimeFromParts(date: LocalDate, time: Opt<string>, defaultTime: string): DateTime {
  const timeStr = !!time ? time : defaultTime;
  return DateTime.fromFormat(`${date.year}-${date.month}-${date.day}T${timeStr}`, "yyyy-M-d'T'H:m")
}
