import { DateTime } from 'luxon'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { listSiteRelatedLogEntries } from '../tools/API'
import { BaseTable, ColumnDef } from './BaseTable'
import { getFullName } from '../tools/AppUtils'
import { formatAsLocalDateTime } from '../tools/Strings'
import { useTranslation } from 'react-i18next'

const columnsWide: ColumnDef[] = [
  { width: 11, field: 'timestamp', headerTextId: 'logTableHeaderAccessed' },
  { width: 12, field: 'targetType', headerTextId: 'logTableHeaderTargetType' },
  { width: 12, field: 'targetSubType', headerTextId: 'logTableHeaderTargetSubType' },
  { width: 8, field: 'actionType', headerTextId: 'logTableHeaderActionType' },
  { width: 16, field: 'party', headerTextId: 'logTableHeaderName' },
  { width: 18, field: 'party.email', headerTextId: 'logTableHeaderEmail' },
  { width: 8, field: 'party.externalId', headerTextId: 'logTableHeaderExternalId' },
  { width: 15, field: 'party.jobTitle', headerTextId: 'logTableHeaderJobTitle' },
]

const columnsNarrow: ColumnDef[] = [
  { field: 'timestamp', headerTextId: 'logTableHeaderAccessed' },
  { field: 'party', headerTextId: 'logTableHeaderName' },
]

export interface LogTableProp {
  siteId: number
}

export function LogTable(p: LogTableProp) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const columns = matches ? columnsWide : columnsNarrow
  const { t } = useTranslation()

  function mapValue(field: string, value: any) {
    if (field === 'timestamp') {
      // TODO: convert to use DateTime only
      return formatAsLocalDateTime(DateTime.fromJSDate(new Date(value)))
    } else if (field === 'targetType') {
      return t(`logTableTargetType${value}`)
    } else if (field === 'targetSubType') {
      return !!value ? t(`logTableTargetSubType${value}`) : ''
    } else if (field === 'actionType') {
      return t(`logTableActionType${value}`)
    } else if (field === 'party') {
      return getFullName(value)
    } else {
      return value
    }
  }

  return (
    <BaseTable
      tableId={`siteLog-${p.siteId}`}
      columns={columns}
      saveArgs={false}
      filterLabelId='logTableFilterLabel'
      valueMapper={mapValue}
      onFetchItems={args => listSiteRelatedLogEntries(p.siteId, args)}
    />
  )
}
