import { DateTime } from "luxon";

export type Opt<T> = T | undefined | null

export interface LocalDate {
  year: number,
  month: number,
  day: number
}

export interface SubtitleHadlerParams {
  inProgress:boolean, 
  hasError:boolean, 
  loadingText:string, 
  subtitleText:string, 
  errorMsg:string
}

export const isDefined = <T>(value: T | undefined | null): value is T =>
  typeof value !== 'undefined' && value !== null;

export function isBlankString(str: Opt<string>): boolean {
  return (str?.trim().length ?? 0) === 0
}

export function isEmptyString(str: Opt<string>) {
  return (str?.length ?? 0) === 0
}

export function isEmpty<T>(list?: T[]) {
  return isDefined(list) && list.length === 0
}

// Input is a timestamp string in the format used by the backend API, e.g. 2020-10-29T14:20:23.048
// Output is a (localized) date-only string suitable for UI representation.
export function formatDateString(str: Opt<string>): Opt<string> {
  if (!str) return null
  // TODO: convert to use DateTime
  const date = new Date(str)
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

export function dateStringToTimestamp(str: Opt<string>) {
  if (!str) return null
  return `${str}T00:00:00.000`
}

export function subtitleHandler(p: SubtitleHadlerParams):string{
    if(p.inProgress){
      return p.loadingText
    }
    if(p.hasError) {
      return p.errorMsg
    }
    return p.subtitleText
}

export function normalizeTimestampString(str: Opt<string>) {

  // Sometimes the timestamps in API responses do not end with the milliseconds part so add it if missing.
  // Alternatively one could always parse timestamp strings to Date objects...
  if (str && !str.match(/\.\d{3}Z?$/)) {
    return `${str}.000`
  } else {
    return str
  }
}

export function truncateToDate(dt: DateTime): LocalDate {
  return { year: dt.year, month: dt.month, day: dt.day }
}

const MILLIS_PER_DAY = 24 * 60 * 60 * 1000

/**
 * @param ref should already be truncated to date.
 * @return number of days between ref and d. Positive if d is in the future.
 */
export function daysDistance(dt: DateTime, ref: LocalDate) {
  let d = truncateToDate(dt)
  return DateTime.fromObject(d).diff(DateTime.fromObject(ref)).toMillis()/MILLIS_PER_DAY
}

/**
 * Check if the given DateTime is at the start of the day in local time. This is meant to be used for UI
 * related operations, and so it works on the accuracy of a minute (that is, seconds don't matter).
 */
export function isStartOfLocalDay(dt: DateTime) {
  const localDt = dt.setZone('local')
  const startOfDay = localDt.startOf('day')
  return Math.floor(Math.abs(startOfDay.diff(localDt).as('minutes'))) === 0
}

/**
 * Check if the given DateTime is at the end of the day in local time. This is meant to be used for UI
 * related operations, and so it works on the accuracy of a minute (that is, seconds don't matter).
 */
 export function isEndOfLocalDay(dt: DateTime) {
  const localDt = dt.setZone('local')
  const endOfDay = localDt.endOf('day')
  return Math.floor(Math.abs(endOfDay.diff(localDt).as('minutes'))) === 0
}

export function shallowEquals(a: Record<string, any>, b: Record<string, any>, emptyIsUndefined = true) {
  return Object.entries(a).every(([key, value]) => {
    let bValue = b[key]

    if (emptyIsUndefined) {

      if (value === '') {
        value = undefined
      }

      if (bValue === '') {
        bValue = undefined
      }
    }

    return Object.is(value, bValue)
  })
}

// Useful to ignore onClicks if text has been selected.
// Not sure if this is the correct way of doing this (would be nicer to base the
// selection on the click event rather than 'global' state).
export function selectionIgnoringClick(onClick: () => void) {
  if (window.getSelection()?.toString() === '') {
    onClick()
  }
}

export function checkDisableConsoleMessages() {
  // Disable console messages in production environment.
  // Would be nicer if the function calls could be stripped completely..
  function noop() {}

  if (process.env.NODE_ENV !== 'development') {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
  }
}

// TODO these could be specified in env too..
export const isStagingEnv = window.location.hostname.match(/-staging/) != null
export const isDevEnv = window.location.hostname.match(/-dev/) != null
export const isLocalEnv = window.location.hostname.match(/localhost/) != null

// From https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path#comment108236765_22129960
export function resolvePath(path: string | string[], obj: any, separator = '.') {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * @returns trimmed string and an undefined string if the trimmed version is empty.
 */
export function blankAsUndefined(str: Opt<string>) {
  if (str === null || str === undefined) {
    return undefined
  } else {
    str = str.trim()
    return str === '' ? undefined : str
  }
}

export function getErrorMessage(err: unknown) {
  if (err instanceof Error) {
    return err.message
  } else {
    return ''
  }
}

export function omit<T, K extends string>(object: T, props: K[]): Omit<T,K> {
  const res: any = { ...object };
  for (const key of props) {
    delete res[key];
  }
  return res;
}

export function unique<T>(array: T[]): T[] {
  return [...new Set(array) ];
}

/**
 * @returns the second argument if it is not null, undefined or NaN; otherwise the first argument is returned.
 */
export function defaultTo<T, U>(defaultValue: T, object: U | null | undefined): T | U {
  return object !== undefined && object !== null && !Number.isNaN(object) ? object : defaultValue
}

/**
 * @returns -1 if first argument is less than second argument, zero if they're equal and 1 otherwise.
 */
export function undefinedLastComparator(a: Opt<string>, b: Opt<string>): -1 | 0 | 1 {
  if (isDefined(a) && isDefined(b)) {
    if (a < b) {
      return -1
    } else if (a > b) {
      return 1
    }
    return 0
  } else if (!isDefined(a)) {
    return 1
  }
  return -1
}

export function handleDownload(blob: Blob, filename: string, preferSaveAs: boolean) {
  const url = URL.createObjectURL(blob)

  if (preferSaveAs) {
    // Since no filename can be specified for the URL or as a parameter to window.open(),
    // use an (hidden and temporary) anchor element that is then clicked after defining href.
    // This way when the browser prompts to save a file, the correct filename is suggested
    // instead of a random looking name based on the URL. Also, in some cases the user
    // might not be able to enter a filename in the save dialog.
    // If no filename is defined in the response or in the anchor element, just use open()
    // - it works e.g. in case of PDFs that are opened in the browser directly.
    // Based on whatever frontend's impl was based on + https://stackoverflow.com/a/19328891
    let anchor = document.createElement('a')
    anchor.style.display = 'none'
    anchor.target = '_blank'
    anchor.href = url
    anchor.download = filename
    anchor.click()
    window.URL.revokeObjectURL(url)
  } else {
    window.open(url)
  }
}

/** Returns true if any of the items match the predicate. */
export function anyMatch<T>(predicate: (item: T) => boolean, items: T[]) {
  return items.map(predicate).reduce((prev, curr) => (prev || curr), false);
}

/** Returns true only if all of the items match the predicate. */
export function allMatch<T>(predicate: (item: T) => boolean, items: T[]) {
  return items.map(predicate).reduce((prev, curr) => (prev && curr), true);
}
