import { useMediaQuery, useTheme } from '@material-ui/core'
import { TFunction, useTranslation } from 'react-i18next'
import { getParty, listPartySites } from '../tools/API'
import { BaseTable, ColumnDef } from './BaseTable'
import { useSetTitle } from '../tools/AppEvents'
import { Site, PageResult } from '../tools/API'
import * as navigator from '../tools/Navigator'
import { getFullName } from '../tools/AppUtils'
import { getErrorMessage } from '../tools/Utils'
import * as snacky from './CustomSnackbarProvider'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { VisibilityOutlined, VisibilityOff } from '@material-ui/icons'
import { TIBox } from './SmallComponents'

const columnsWide: ColumnDef[] = [
  { width: 25, field: 'name', headerTextId: 'sitesHeaderName', sortBy: 'NAME' },
  { width: 16, field: 'streetAddress', headerTextId: 'sitesHeaderStreet', sortBy: 'STREET_ADDRESS' },
  { width: 9, field: 'postCode', headerTextId: 'sitesHeaderZip', sortBy: 'POST_CODE' },
  { width: 12, field: 'city', headerTextId: 'sitesHeaderCity', sortBy: 'CITY' },
  { width: 10, field: 'isPublished', headerTextId: 'sitesHeaderPublished', sortBy: 'IS_PUBLISHED'},
]

const columnsNarrow: ColumnDef[] = [
  { width: 45, field: 'name', headerTextId: 'sitesHeaderName', sortBy: 'NAME' },
  { width: 16, field: 'streetAddress', headerTextId: 'sitesHeaderStreet', sortBy: 'STREET_ADDRESS' },
  { width: 12, field: 'isPublished', headerTextId: 'sitesHeaderPublished', sortBy: 'IS_PUBLISHED'},
]


const visibleMarker = <TIBox tooltipKey={'sitesTableVisible'} icon={<VisibilityOutlined/>}/>
const notVisibleMarker = <TIBox tooltipKey={'sitesTableNotVisible'} icon={<VisibilityOff/>}/>

function mapValue(t: TFunction<"translation">, field: string, value: any) {
  if (field === 'isPublished') {
    return value === true ? visibleMarker : notVisibleMarker;
  } else {
    return value
  }
}

class ConstructablePageResult<T> implements PageResult<T> {
  results: T[]
  page: number
  totalResults: number
  lastMatch?: (number|string)[]
  constructor(results: T[], page: number, totalResults: number, lastMatch?: (number|string)[]) {
    this.results = results
    this.page = page
    this.totalResults = totalResults
    this.lastMatch = lastMatch
  }
}

export interface PartySitesTableProp{
  partyId: number
}

interface PartySitesState {
  member?: string
}

export function PartySitesTable(p: PartySitesTableProp) {
  const { t } = useTranslation()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const columns = matches ? columnsWide : columnsNarrow
  const tableId = `party/sites-${p.partyId}`
  const [memberName, setMemberName] = useState(((navigator.getCurrentState() ?? {}) as PartySitesState).member)
  const { enqueueSnackbar } = useSnackbar()

  async function loadMember() {
    try {
      let member = await getParty(p.partyId)
      setMemberName(getFullName(member))
    } catch (err) {
      console.log(`Error fetching: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchSiteDetails', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
  }

  useEffect(() => {
    if (memberName === undefined) {
      loadMember()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useSetTitle(t('partySitesTitle', {name: memberName}))

  return (
    <BaseTable
      key={tableId} // Without the key table searchArgs aren't loaded from storage (initialized) when one navigates from customer specific sites to all sites.
      tableId={tableId}
      columns={columns}
      onOpenDetails={(newSite) => {
        navigator.push(`/site/${newSite!.id}`, {site: newSite})
      }}
      onFetchItems={args => listPartySites(p.partyId).then(results => {
        return new ConstructablePageResult<Site>(results, 0, results.length)
      })}
      valueMapper={(field, value) => mapValue(t, field, value)}
      overrideArgs={{page: 0}}
    />
  )
}
