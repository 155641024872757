import { DateTime } from 'luxon'
import { Opt, LocalDate } from '../tools/Utils'
import { Grid, TextField, TextFieldProps } from '@material-ui/core'

interface DateItemProp {
  date?: Opt<LocalDate>
  onChanged: (date?: LocalDate) => void
}

export function DateItem(p: DateItemProp & TextFieldProps) {
  const {date, onChanged, ...rest} = p

  // In admin-ui a custom clear button was used but in this project + Firefox 91
  // couldn't figure out how to get rid of the default clear button provided by the browser.
  // => no clear button in Chrome. But in the context of this app the
  return (
    <Grid item>
      <TextField
        {...rest}
        type='date'
        variant='outlined'
        defaultValue={p.date ? DateTime.fromObject({ ...p.date, hour: 0, minute: 0 }).toISODate() : null}
        onChange={event => p.onChanged(parseDate(event.target.value))}
        InputLabelProps={{
          shrink: true // Without this the label overlaps with "dd / mm / yyyy" text (label isn't positioned on the top edge)
        }}
      />
    </Grid>
  )
}

function parseDate(str: string): LocalDate | undefined {
  // TODO: convert to only use DateTime
  try {
    // This function gets called already when 1 year digit has been entered manually.
    // => handle incomplete dates as undefined.
    const d = new Date(str)
    const { year, month, day } = DateTime.fromJSDate(d);
    if (d.getFullYear() > 1000) {
      return { year, month, day };
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
}
