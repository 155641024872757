import React, { useState } from 'react'
import { Grid, Typography, TextField, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { getErrorMessage, Opt } from '../tools/Utils'
import { Party } from '../tools/API'
import * as rutils from '../tools/ReactUtils'
import * as api from '../tools/API'
import { getFullName } from '../tools/AppUtils'
import * as snacky from './CustomSnackbarProvider'
import { useSnackbar } from 'notistack'
import { SecondaryText } from './SmallComponents'

import Autocomplete from '@material-ui/lab/Autocomplete'

export interface PartySelectorItemProp {
  label: string
  initial: Opt<Party>
  onChange: (party: Opt<Party>) => void
  error?: string
  autoFocus?: boolean
  hasExternalId?: boolean
  disabled: boolean
  excludePartyIds?: Set<number>
}

export function PartySelectorItem(prop: PartySelectorItemProp) {
  // Use null instead of undefined as undefined would make the element initially an uncontrolled one.
  const [selected, setSelected] = useState<Opt<Party>>(prop.initial ?? null)
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  const searchResults = useSearchParty(searchTerm, selected ? [selected] : [], prop.excludePartyIds, prop.hasExternalId)
  const { t } = useTranslation()

  return (
    <Grid item>
      <Autocomplete
        fullWidth
        options={searchResults}
        value={selected}
        disabled={prop.disabled}
        getOptionLabel={party => getFullName(party) ?? ''}
        getOptionSelected={party => party.id === selected?.id}
        renderOption={(party, state) => (<PartyOptionItem party={party} />)}
        onChange={(_event, value) => {
          if (typeof value !== 'string') {
            setSelected(value)
            prop.onChange(value)
          }
        }}
        onInputChange={(_event, value, reason) => {
          if (reason === 'input') {
            setSearchTerm(value)

          } else if (reason === 'clear') {
            setSearchTerm(undefined)
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={prop.autoFocus}
            label={prop.label}
            variant='outlined'
            error={prop.error !== undefined}
            helperText={prop.error}
            />
        )}
        noOptionsText={t('noPartySearchResults')}
        />
    </Grid>
  )
}

function useSearchParty(searchTerm: string | undefined, initialResults: Party[], excludePartyIds?: Set<number>, hasExternalId?: boolean) {
  const [searchResults, setSearchResults] = useState(initialResults)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  function updateSearchResults() {

    async function searchParties() {

      try {
        const result = await api.searchParties({ searchTerm: searchTerm }, false, hasExternalId)

        if (excludePartyIds) {
          setSearchResults(result.results.filter(p => !excludePartyIds.has(p.id)))
        } else {
          setSearchResults(result.results)
        }

      } catch (err) {
        console.log(`Error searching parties: ${JSON.stringify(err)}`)
        enqueueSnackbar(t('errSearchParties', {message: getErrorMessage(err)}), snacky.errorOpts)
      }
    }

    if ((searchTerm?.length ?? 0) > 0) {
      searchParties()
    } else {
      setSearchResults([])
    }
  }

  rutils.useDebouncedCallback(searchTerm, updateSearchResults, 500)

  return searchResults
}

function PartyOptionItem({party}: {party: Party}) {

  return (
    <Box flexDirection='column'>
      <Typography variant="subtitle1">{getFullName(party)}</Typography>
      <SecondaryText>{party.jobTitle ?? ' '}</SecondaryText>
      <SecondaryText>{party.externalId}</SecondaryText>
    </Box>
  )
}
