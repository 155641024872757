import React from 'react'
import { Grid, Typography, Accordion, AccordionSummary } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { useDetailCardStyles } from './DetailsCard'

export interface LinkCardProp {
  cardId: string
  title: string
  icon: React.ReactNode
  onClick: () => void
}

export function LinkCard(prop: LinkCardProp) {
  const classes = useDetailCardStyles()

  // Use Accordion instead of Card to get the look 1:1 with DetailsCard.
  return (
    <Accordion expanded={false} onChange={(_event, expanded) => prop.onClick()}>
      <AccordionSummary expandIcon={<ArrowForward/>} className={classes.summary}>
        <Grid container direction='row' alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Grid item>
                {prop.icon}
              </Grid>
              <Grid item>
                <Typography variant="h6">{prop.title}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
    </Accordion>
  )
}
