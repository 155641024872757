import { useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography, makeStyles, RadioGroup, FormControlLabel, FormControl, FormLabel, Radio, useMediaQuery, useTheme } from '@material-ui/core'
import { Check, ReportOutlined } from '@material-ui/icons'
import { useSnackbar } from 'notistack'

import { ContractLine, Customer, exportContractLines, reportContractLineError, SearchArgs, searchContractLines, Site } from '../tools/API'
import { BaseTable, ColumnDef } from './BaseTable'
import { useSetTitle } from '../tools/AppEvents'
import { TextItem, TIBox, TIButton } from './SmallComponents'
import { handleDownload, isDefined, isEmptyString } from '../tools/Utils'
import * as snacky from './CustomSnackbarProvider'
import { getFullName } from '../tools/AppUtils'

const columns: ColumnDef[] = [
  { width: 7, field: 'contract.site.customer.externalId', headerTextId: 'contractLinesHeaderCustomerExtId', sortBy: 'CUSTOMER_EXTERNAL_ID' },
  { width: 9, field: 'contract.site.customer.name', headerTextId: 'contractLinesHeaderCustomerName', sortBy: 'CUSTOMER_NAME' },
  { width: 10, field: 'contract.site.name', headerTextId: 'contractLinesHeaderSiteName', sortBy: 'SITE_NAME' },
  { width: 10, field: 'contract.site.streetAddress', headerTextId: 'contractLinesHeaderSiteAddress', sortBy: 'STREET_ADDRESS' },
  { width: 6, field: 'contract.site.postCode', headerTextId: 'contractLinesHeaderSitePostCode', sortBy: 'POST_CODE' },
  { width: 7, field: 'contract.site.city', headerTextId: 'contractLinesHeaderSiteCity', sortBy: 'CITY' },
  { width: 7, field: 'contract.externalId', headerTextId: 'contractLinesHeaderContractExtId', sortBy: 'CONTRACT_EXTERNAL_ID' },
  { width: 5, field: 'lineNumber', headerTextId: 'contractLinesHeaderLineNumber', sortBy: 'LINE_NUMBER' },
  { width: 9, field: 'codeWithName', headerTextId: 'contractLinesHeaderName', sortBy: 'SERVICE_CODE' },
  { width: 6, field: 'profitCenter.externalId', headerTextId: 'contractLinesHeaderProfitCenterExtId', sortBy: 'PROFIT_CENTER_EXTERNAL_ID' },
  // Disabled due to possibly incorrect data until data model is fixed.
  /*{ width: 9, field: 'responsibleParty', headerTextId: 'contractLinesHeaderResponsiblePartyName', sortBy: 'RESPONSIBLE_PARTY' },*/
  { width: 5, field: 'isActive', headerTextId: 'contractLinesHeaderIsActive', sortBy: 'IS_ACTIVE' },
  { width: 5, field: 'reportError', headerTextId: 'contractLinesHeaderReportError'}
]

function mapValue(t: TFunction<"translation">, field: string, value: any, item: ContractLine, onReportError: (item: ContractLine) => void) {
  if (field === 'isActive' && value === true) {
    return <TIBox tooltipKey={'contractLinesTableActive'} icon={<Check/>}/>
  } else if (field === 'reportError') {
    return <TIButton tooltipKey={'contractLinesTableReportError'} icon={<ReportOutlined/>} onClick={() => onReportError(item)}/>
  } else if (field === 'responsibleParty') {
    return isDefined(value) ? getFullName(value) : ''
} else {
    return value
  }
}

const useTableStyles = makeStyles(theme => (
  {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    }
  }
))

export function ContractLinesTable() {
  const { t } = useTranslation()
  const classes = useTableStyles();
  const { enqueueSnackbar } = useSnackbar()
  useSetTitle(t('drawerMenuContracts'))

  const [lineWithError, setLineWithError] = useState<ContractLine | null>(null)
  const [inProgress, setInProgress] = useState(false)
  const [contractPrefix, setContractPrefix] = useState<number | undefined>(undefined)

  const doSendErrorReport = async (lineId: number, description: string) => {
    setInProgress(true)
    try {
      await reportContractLineError(lineId, {description: description})
      enqueueSnackbar(t('contractLinesErrorReportSent'), snacky.infoOpts)
    } catch (err) {
      enqueueSnackbar(t('errSendContractLineErrorReport', {message: err}), snacky.errorOpts)
    } finally {
      setLineWithError(null)
      setInProgress(false)
    }
  }

  const doExport = async (args: SearchArgs, extraArgs?: any) => {
    setInProgress(true)
    try {
      const blob = await exportContractLines(args, extraArgs)
      handleDownload(blob, 'contract-line-export.xlsx', true)
    } catch (err) {
      enqueueSnackbar(t('errExport', {message: err}), snacky.errorOpts)
    } finally {
      setInProgress(false)
    }
  }

  const mapPrefixFilterValue = (value: string) => {
    const prefix = Number.parseInt(value, 10)
    if (Number.isNaN(prefix)) {
      return undefined
    }
    return prefix
  }

  return (
    <>
      <BaseTable
        tableId='contracts'
        columns={columns}
        filterLabelId='contractsSearchLabel'
        extraFilter={<ContractPrefixFilter onChange={(event) => setContractPrefix(mapPrefixFilterValue(event.target.value))}/>}
        onFetchItems={searchContractLines}
        onExport={doExport}
        valueMapper={(field, value, item) => mapValue(t, field, value, item, (item) => setLineWithError(item))}
        extraArgs={{contractNumberPrefix: contractPrefix}}
      />
      {isDefined(lineWithError) &&
        <ReportContractLineErrorDialog
          line={lineWithError}
          onSubmit={(lineId, description) => doSendErrorReport(lineId, description)}
          onCancel={() => setLineWithError(null)}
          inProgress={inProgress}
        />
      }
      <Backdrop className={classes.backdrop} open={!isDefined(lineWithError) && inProgress}>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  )
}

const usePrefixFilterStyles = makeStyles(theme => (
  {
    normal: {
      paddingLeft: 8
    },
    restrictedWidth: {
      paddingLeft: 8,
      maxWidth: '15rem',
      '& .MuiFormGroup-root': {
        justifyContent: 'space-between'
      }
    },
    filterLabel: {
      fontSize: 'inherit',
      paddingBottom: 4
    },
    optionLabel: {
      '& .MuiFormControlLabel-label': {
        fontSize: 'inherit'
      },
      '& .MuiRadio-root': {
        paddingBottom: 4,
        paddingTop: 4
      }
    }
  }
))

function ContractPrefixFilter({onChange}: {onChange: (value: any) => void}) {
  const { t } = useTranslation()
  const classes = usePrefixFilterStyles()
  const theme = useTheme()
  const wide = useMediaQuery(theme.breakpoints.up('lg'))
  const wider = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <FormControl className={wider ? classes.normal : classes.restrictedWidth} component="fieldset">
      <FormLabel className={classes.filterLabel} component="legend">{t('contractPrefixFilterLabel')}</FormLabel>
      <RadioGroup row={wide} onChange={onChange} defaultValue="all">
        <FormControlLabel className={classes.optionLabel} value="all" label={t('contractPrefixOptionAll')} control={<Radio/>} />
        <FormControlLabel className={classes.optionLabel} value="1" label={t('contractPrefixOption1')} control={<Radio/>} />
        <FormControlLabel className={classes.optionLabel} value="3" label={t('contractPrefixOption3')} control={<Radio/>} />
        <FormControlLabel className={classes.optionLabel} value="5" label={t('contractPrefixOption5')} control={<Radio/>} />
      </RadioGroup>
    </FormControl>
  )
}

const useDialogStyles = makeStyles(theme => (
  {
    lastRowNoBorder: {
      '& .MuiTableRow-root:last-child .MuiTableCell-root': {
        borderBottom: '0px'
      }
    }
  }
))

interface ReportContractLineErrorDialogProp {
  line: ContractLine
  onSubmit: (lineId: number, description: string) => void
  onCancel: () => void
  inProgress?: boolean
}

function ReportContractLineErrorDialog(p: ReportContractLineErrorDialogProp) {
  const { t } = useTranslation()
  const classes = useDialogStyles()
  const [description, setDescription] = useState<string>('')
  const disabled = (p.inProgress ?? false)

  const onSave = () => p.onSubmit(p.line.id, description)

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth='xs'
    >
      <DialogTitle>{t('contractLinesErrorDialogTitle')}</DialogTitle>
      <DialogContent>
        <Grid container direction='column'>
          <Grid item>
            <Typography variant="subtitle2">{t('contractLinesErrorDialogBody')}</Typography>
          </Grid>
          <Grid item>
            <Box py={2}>
              <TableContainer>
                <Table size='small'>
                  <TableBody className={classes.lastRowNoBorder}>
                    <TableRow>
                      <TableCell>
                        {t('contractLinesErrorDialogCustomer')}
                      </TableCell>
                      <TableCell data-testid="errorline-customer">
                        {renderErrorLineCustomer(t, p.line.contract.site.customer)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t('contractLinesErrorDialogSite')}
                      </TableCell>
                      <TableCell data-testid="errorline-site">
                        {renderErrorLineSite(t, p.line.contract.site)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t('contractLinesErrorDialogProductGroup')}
                      </TableCell>
                      <TableCell data-testid="errorline-productGroup">
                        {`${p.line.serviceCode} ${p.line.name}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t('contractLinesErrorDialogProfitCenter')}
                      </TableCell>
                      <TableCell data-testid="errorline-profitCenter">
                        {`${p.line.profitCenter.externalId}`}
                      </TableCell>
                    </TableRow>
                    {// Disabled due to possibly incorrect data until data model is fixed.
                    /*<TableRow>
                      <TableCell>
                        {t('contractLinesErrorDialogResponsibleParty')}
                      </TableCell>
                      <TableCell data-testid="errorline-responsibleParty">
                        {renderErrorLineResponsibleParty(t, p.line.responsibleParty)}
                      </TableCell>
                    </TableRow>*/}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <TextItem
            name='description'
            disabled={disabled}
            label={t('contractLinesErrorDialogDescriptionLabel')}
            value={description}
            multiline={true}
            maxLength={3000}
            minRows={3}
            maxRows={8}
            onChange={event => setDescription(event.target.value)}
            autoFocus={true}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={p.onCancel} disabled={disabled} color='primary'>{t('buttonCancel')}</Button>
        <Button
          onClick={onSave}
          disabled={disabled || isEmptyString(description)}
          color='primary'
        >
          {p.inProgress ? <CircularProgress variant="indeterminate" size={30} /> : t('buttonSend')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function renderErrorLineCustomer(t: TFunction<"translation">, customer?: Customer) {
  if (isDefined(customer)) {
    return `${customer.externalId} ${customer.name}`
  }
  return t('contractLinesErrorDialogNoCustomer')
}

function renderErrorLineSite(t: TFunction<"translation">, site: Site) {
  return <>
    {`${site.name}`}
    <br/>
    {`${site.streetAddress}, ${site.postCode} ${site.city}`}
  </>
}

// Disabled due to possibly incorrect data until data model is fixed.
/*function renderErrorLineResponsibleParty(t: TFunction<"translation">, party?: Party) {
  if (isDefined(party)) {
    return `${getFullName(party)}`
  }
  return t('contractLinesErrorDialogNoResponsibleParty')
}*/
