import { Drawer, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { DrawerMenu } from './DrawerMenu'

// The responsive drawer implementation is based on https://material-ui.com/components/drawers/#responsive-drawer

export const drawerWidth = 240
export const drawerMobileBreakpoint = 'lg'

const useStyles = makeStyles(theme => (
  {
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up(drawerMobileBreakpoint)]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
  }
))

export interface ResponsiveDrawerProp {
  /** Set to true if the mobile menu should be shown. */
  mobileOpen: boolean

  /** Called when the drawer is closed (mobile case). */
  onClose: () => void
}

/**
 * Display a mobile-style drawer menu when the viewport is narrow.
 * When the viewport is wide, then keep the drawer menu always visible next to the content.
 */
export function ResponsiveDrawer(prop: ResponsiveDrawerProp) {
  const classes = useStyles()
  const theme = useTheme()
  const isWide = useMediaQuery(theme.breakpoints.up(drawerMobileBreakpoint))

  const narrowDrawer = (
    <Drawer
      variant="temporary"
      anchor="left"
      open={prop.mobileOpen}
      onClose={prop.onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <DrawerMenu/>
    </Drawer>
  )

  const wideDrawer = (
    <Drawer classes={{
        paper: classes.drawerPaper
      }}
      variant="permanent"
      open
    >
      <DrawerMenu/>
    </Drawer>
  )

  return (
    <nav className={classes.drawer}>{isWide ? wideDrawer : narrowDrawer}</nav>
  )
}
