import React, { useState } from 'react'
import { Box, Grid, Typography, Fade } from '@material-ui/core'
import { EditOutlined, Done, Close } from '@material-ui/icons'
import { MarkdownInstructions, TextItem, TIButton } from './SmallComponents'
import { MarkdownText } from './MarkdownText'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export function LabeledItemGroup({children}: {children: JSX.Element[]}) {
  return (
    <Grid container direction='column' spacing={1}>
      {children}
    </Grid>
  )
}

export interface LabeledItemProp {
  label: string
  value: string
  info?: string

  // If defined, then display an edit button -> input field + ok/cancel buttons -> onSave called when ok is pressed.
  onSave?: (value: string) => void

  action?: React.ReactNode

  maxLength?: number

  tooltipKey?: string

  markdown?: boolean
}

export function LabeledItem(prop: LabeledItemProp) {
  const [editing, setEditing] = useState(false)
  const [editValue, setEditValue] = useState<string>(prop.value)
  const [showEditButton, setShowEditButton] = useState(false)
  const { t } = useTranslation()

  useEffect(() => { setEditValue(prop.value) }, [prop.value])

  function handleSave() {
    setEditing(false)
    if (prop.onSave) prop.onSave(editValue)
  }

  if (editing) {
    return (
      <Grid key='li-edit' item style={{paddingTop: 10}}>
        <Box display='flex'>
          <Box flexGrow={1}>
            <TextItem
              autoFocus
              label={prop.label}
              value={editValue}
              maxLength={prop.maxLength}
              onChange={event => setEditValue(event.target.value)}
              onEnterPressed={handleSave}
              info={prop.info}
              infoTooltip={ (prop.markdown ?? false) ? t('buttonShowMarkdownInstructionsToolTip') : undefined }
              infoTooltipHide={ (prop.markdown ?? false) ? t('buttonHideMarkdownInstructionsToolTip') : undefined }
              infoBox={(prop.markdown ?? false) ? <MarkdownInstructions/> : undefined}
            />
          </Box>
          <Box>
            <TIButton
              tooltipKey='buttonSaveTooltip'
              icon={<Done/>}
              onClick={handleSave}
            />
          </Box>
          <Box>
            <TIButton
              edge='end'
              tooltipKey='buttonCancelEditTooltip'
              icon={<Close/>}
              onClick={() => {
                setEditing(false)
                setEditValue(prop.value)
              }}
            />
          </Box>
        </Box>
      </Grid>
    )

  } else {
    const text = prop.value === '' ? '\u00A0' : prop.value

    return (
      <Grid key='li-ro' item>
        <Box display='flex' alignItems='center'
          onMouseEnter={(_event) => setShowEditButton(true)}
          onMouseLeave={(_event) => setShowEditButton(false)}
        >
          <Box flexGrow={1}>
            <Grid container direction='column'>
              <Grid item>
                <Typography variant='subtitle2'>{prop.label}</Typography>
              </Grid>
              <Grid item>
                { (prop.markdown ?? false) ?
                <MarkdownText text={text}/>
                :
                <Typography variant='body1'>{text}</Typography>
                }
              </Grid>
            </Grid>
          </Box>
          {
            prop.onSave &&
            <Fade in={showEditButton}>
              <Box>
                <TIButton
                  edge='end'
                  icon={<EditOutlined/>}
                  onClick={() => setEditing(true)}
                  tooltipKey='buttonEditTooltip'
                />
              </Box>
            </Fade>
          }
          {
            prop.action
          }
        </Box>
      </Grid>
    )
  }
}
