import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import { SnackbarProvider, OptionsObject } from 'notistack'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1)
  },
}))

const notistackRef = React.createRef<any>()

export function CustomSnackbarProvider({ children }: { children: JSX.Element }) {
  const classes = useStyles()

  const onClickDismiss = (key: string | number) => () => {
      notistackRef.current.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      // classes={{
      //   variantError: classes.variantError
      // }}
      iconVariant={{
        error: <ErrorIcon fontSize='small' className={classes.icon} />
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      ref={notistackRef}
      action={(key: string | number) => (
        <IconButton size='small' aria-label='close' color='inherit' onClick={onClickDismiss(key)}>
          <CloseIcon fontSize='small' />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}

export const errorOpts: OptionsObject = { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }
export const infoOpts: OptionsObject = { variant: 'info', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }
