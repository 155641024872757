import { ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Typography } from "@material-ui/core"
import { TFunction } from "i18next"
import { isDefined } from "../tools/Utils"

export interface BatchDialogProps<T> {
  operations: BatchOperation<T>[]
  /** Method to call when all operations have completed and the dialog is closed. */
  onCompletion: () => void
  /** Translation key for dialog title. */
  titleKey: string
  /** Translation key for "in progress" message. */
  inProgressKey: string
  /** Translation key for "all operations completed successfully" message. */
  successKey: string
  /** Translation key for message that is shown above the failures. */
  failedKey: string
  /** Method to render the failures. */
  failureEntriesRenderer?: (translationFunction: TFunction, failed: BatchOperationError[]) => ReactNode
}

export interface BatchOperation<T> {
  operation: () => Promise<T>
  /** User-readable identifier for the batch operation, shown in case of error. */
  identifier: string
  /** Method to call when a single operation succeeds. */
  onSuccess: (result: T) => void
  /** Method to call when a single operation fails. */
  onError: (error: unknown) => void // ???
}

export interface BatchOperationError {
  identifier: string | number
  error: any
}

export function BatchOperationDialog<T>(prop: BatchDialogProps<T>) {
  const { t } = useTranslation()
  const { operations } = prop
  const [opsCount, setOpsCount] = useState(prop.operations.length)
  const [failed, setFailed] = useState<BatchOperationError[]>([])
  const [completedOps, setCompletedOps] = useState(0)

  useEffect(() => {
    executeOperations(operations)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operations])

  function executeOperations(ops: BatchOperation<T>[]) {
    ops.forEach(operation => {
      return operation.operation().then(
        result => {
          setCompletedOps(c => c + 1)
          operation.onSuccess(result)
        },
        error => {
          setFailed(f => f.concat([{identifier: operation.identifier, error: error}]))
          setCompletedOps(c => c + 1)
          operation.onError(error)
        }
      )
    })
  }

  function retryFailedOps() {
    const failedOpIds = failed.map(failed => failed.identifier)
    const failedOperations = operations.filter(op => failedOpIds.includes(op.identifier))
    setOpsCount(failedOperations.length)
    setCompletedOps(0)
    setFailed([])
    executeOperations(failedOperations)
  }

  const done = completedOps === opsCount && opsCount > 0
  return <Dialog
      open={true}
      fullWidth={true}
      maxWidth='sm'
    >
    <DialogTitle>{t(prop.titleKey)}</DialogTitle>
      <DialogContent>
        { !done &&
          <>
          <Box display="flex" justifyContent="space-between">
            <DialogContentText>{t(prop.inProgressKey)}</DialogContentText>
            <Box minWidth={60} marginTop="2px" textAlign="right">
              <Typography variant="body2" color="textSecondary">
                {`${completedOps} / ${opsCount}`}
              </Typography>
            </Box>
          </Box>
          <Box>
            <LinearProgress variant={'determinate'} value={completedOps/opsCount*100} />
          </Box>
        </>
      }
      { done && failed.length > 0 &&
        <>
          <DialogContentText>
            {t(prop.failedKey)}
          </DialogContentText>
          <Box>
            {isDefined(prop.failureEntriesRenderer) && prop.failureEntriesRenderer(t, failed)}
          </Box>
        </>
      }
      { done && failed.length === 0 &&
        <DialogContentText>
          {t(prop.successKey)}
        </DialogContentText>
      }
    </DialogContent>
    <DialogActions>
      <Box display="flex" justifyContent="space-between" flexDirection="row-reverse">
        <Button disabled={!done} onClick={prop.onCompletion}>{t('buttonClose')}</Button>
        { done && failed.length > 0 &&
          <Box marginRight={3}><Button onClick={retryFailedOps}>{t('batchOperationDialogButtonRetry')}</Button></Box>
        }
      </Box>
    </DialogActions>
  </Dialog>
}
