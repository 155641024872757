import React, { useState } from 'react'
import { Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Box, makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { DeleteOutlined, Edit, ExpandMore, LocationCity, LockOutlined } from '@material-ui/icons'
import { DetailsCard } from './DetailsCard'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Opt, blankAsUndefined, getErrorMessage, isDefined, subtitleHandler } from '../tools/Utils'
import { getFullName, compareParties } from '../tools/AppUtils'
import * as api from '../tools/API'
import { Party, TeamMember, TeamType } from '../tools/API'
import { splitToGroups, TeamMemberGroup, TeamMemberGroupType } from '../tools/TeamMemberGroup'
import * as snacky from './CustomSnackbarProvider'
import { useSnackbar } from 'notistack'
import { PartySelectorItem } from './PartySelectorItem'
import { SecondaryText, TextItem, TIBox } from './SmallComponents'
import { ConfirmDialog } from './ConfirmDialog'
import { TIButton } from './SmallComponents'

export enum TeamCardType {
  /** Site specific team that only includes ISS-employees. */
  ServiceTeam = 'ServiceTeam',

  /** Site specific team that only includes external employees (ones that do not have an externalId). */
  SiteOthers = 'SiteOthers',

  /** Customer specific team. */
  CustomerTeam = 'CustomerTeam'
}

export interface TeamCardProp {
  type: TeamCardType
  ownerId?: number // either a site id or customer external_id, undefined if not known yet.
  title: string
  icon: React.ReactNode
  grouped: boolean
  disabled?: boolean
  onSitesClick: (member: TeamMember) => void
}

interface TeamCardDefs {
  membersFilter?: (m: TeamMember) => boolean
  teamType: TeamType
  requiresExternalId?: boolean
}

function getDefs(cardType: TeamCardType): TeamCardDefs {

  switch (cardType) {
    case TeamCardType.ServiceTeam:
      return {
        membersFilter: m => m.externalId != null,
        teamType: TeamType.Site,
        requiresExternalId: true
      }

    case TeamCardType.SiteOthers:
      return {
        membersFilter: m => m.externalId == null,
        teamType: TeamType.Site,
        requiresExternalId: false
      }

    case TeamCardType.CustomerTeam:
      return {
        membersFilter: undefined,
        teamType: TeamType.Customer,
        requiresExternalId: true
      }
  }
}

const useStyles = makeStyles(theme => (
  {
    groupAccordion: {
      '&:before': { // Hide the separator line that is only shown between AccordionDetails' children (not on top of the first one).
        display: 'none'
      },
      borderTop: '1px solid #ddd',
      '&:last-child': { // Add margin at the bottom (of a collapsed accordion) to allow rounded corners of the card show correctly.
        marginBottom: '5px'
      },
      '&.MuiAccordion-root.Mui-expanded:last-child': { // Awkward, but was needed to override the square cornered defaults for expanded accordion.
        marginBottom: '5px'
      },
      '&.Mui-expanded': { // Remove default expansion effect of neighboring accordions when one is expanded
        margin: 0
      }
    },
    groupAccordionSummary: {
      backgroundColor: '#F5F5F5'
    },
    groupAccordionDetails: {
      paddingLeft: 0,
      paddingRight: 0
    },
    ungroupedGrid: {
      paddingBottom: '16px'
    }
  }
))

export function TeamCard(p: TeamCardProp) {
  const defs = getDefs(p.type)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [members, setMembers] = useState<TeamMember[]>([])
  const [memberGroups, setMemberGroups] = useState<TeamMemberGroup[]>([])
  const [memberCount, setMemberCount] = useState<number>(0)
  const [confirmDeleteMember, setConfirmDeleteMember] = useState<Opt<TeamMember>>(undefined)
  const [deletingMemberId, setDeletingMemberId] = useState<Opt<number>>(undefined)
  const [editingMember, setEditingMember] = useState<Opt<TeamMember>>(undefined)
  const [teamMemberIds, setTeamMemberIds] = useState<Set<number>>(new Set())

  async function fetchMembers() {
    try {
      setInProgress(true)
      const membersResp = await api.listTeamMembers(defs.teamType, p.ownerId!, p.type)
      const responsibleParties = p.type === TeamCardType.ServiceTeam ? await api.listResponsibleParties(p.ownerId!) : []
      setInProgress(false)
      let members = membersResp

      if (defs.membersFilter) {
        members = members.filter(defs.membersFilter)
      }

      setMembers(members)
      setMemberCount(members.length + responsibleParties.length)
      p.grouped && setMemberGroups(splitToGroups(members, responsibleParties))
      setTeamMemberIds(new Set(members.map(m => m.id)))
    } catch (err) {
      console.log(`Error fetching: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchGeneric', {message: getErrorMessage(err)}), snacky.errorOpts)
      setHasError(true)
      setInProgress(false)
    }
  }

  async function fetchMemberCount() {
    const infoCardType = api.SiteInfoCardTypeByTeamCardType[p.type]!;
    try {
      setInProgress(true)
      const counts = await api.getCountsForSiteInfoCards(p.ownerId!, [infoCardType])
      setInProgress(false)
      setMemberCount(counts[infoCardType])
    } catch (err) {
      console.log(`Error fetching: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchGeneric', {message: getErrorMessage(err)}), snacky.errorOpts)
      setHasError(true)
      setInProgress(false)
    }
  }

  async function handleSave(party: Party, role: Opt<string>, editing: boolean) {
    try {

      if (editing) {
        await api.modifyTeamMember(defs.teamType, p.ownerId!, party, role)
      } else {
        await api.addTeamMember(defs.teamType, p.ownerId!, party, role)
      }

      await fetchMembers()
    } catch (err) {
      console.log(`Failed to add team member: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errAddTeamMember', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
  }

  async function handleDelete(member: TeamMember) {
    try {
      await api.removeTeamMember(defs.teamType, p.ownerId!, member)
      await fetchMembers()
    } catch (err) {
      console.log(`Failed to remove team member: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errDelTeamMember', {message: getErrorMessage(err)}), snacky.errorOpts)
    } finally {
      setDeletingMemberId(undefined)
    }
  }

  const onMemberEdit = (member: TeamMember) => {
    setAddDialogOpen(true)
    setEditingMember(member)
  }

  return (
    <DetailsCard
      cardId={`team-${p.type}`}
      title={p.title}
      noPadContent={true}
      subtitle={
        subtitleHandler({
          inProgress, 
          hasError, 
          loadingText:t('teamCardLoading'), 
          subtitleText:t('teamSubtitle', {count: memberCount}), 
          errorMsg:t('errFetchGenericNoDetails')
        })
      }
      icon={p.icon}
      onAddClicked={isDefined(p.ownerId) ? () => setAddDialogOpen(true) : undefined}
      addTooltipKey='teamCardAddTooltip'
      onFirstExpansion={isDefined(p.ownerId) && p.type === TeamCardType.SiteOthers ? fetchMembers : undefined}
      onInitIfCollapsed={isDefined(p.ownerId) && p.type === TeamCardType.SiteOthers ? fetchMemberCount : undefined}
      onInit={isDefined(p.ownerId) && p.type !== TeamCardType.SiteOthers ? fetchMembers : undefined}
      disabled={p.disabled}
    >
      { p.grouped ?
        <TeamMemberGroupedList
          groups={memberGroups}
          teamType={p.type}
          onEdit={onMemberEdit}
          onDelete={setConfirmDeleteMember}
          onSitesClicked={member => p.onSitesClick(member)}
          isDisabled={member => deletingMemberId === member.id}
        />
      :
        <TeamMemberUngroupedList
          members={members}
          onEdit={onMemberEdit}
          onDelete={setConfirmDeleteMember}
          onSitesClicked={member => p.onSitesClick(member)}
          isDisabled={member => deletingMemberId === member.id}
        />
      }
      { /* Assuming that the number of members is typically quite small so just list all of 'em. */ }
      <>
        { addDialogOpen &&
          <AddTeamMemberDialog
            open={true}
            member={editingMember}
            excludePartyIds={teamMemberIds}
            hasExternalId={defs.requiresExternalId}
            onAction={(party, role) => {
              const editing = editingMember !== undefined
              setEditingMember(undefined)
              if (party) handleSave(party, role, editing)
              setAddDialogOpen(false)
            }}
          />
        }
        { confirmDeleteMember &&
          <ConfirmDialog
            open={confirmDeleteMember !== undefined}
            title={t('confirmTeamMemberRemoveTitle')}
            text={t('confirmTeamMemberRemoveText', {name: confirmDeleteMember ? getFullName(confirmDeleteMember!) : ''})}
            onAction={(yes) => {
              const member = confirmDeleteMember!
              setConfirmDeleteMember(undefined)

              if (yes === true) {
                setDeletingMemberId(member.id)
                handleDelete(member)
              }
            }}
          />
        }
      </>
    </DetailsCard>
  )
}

interface TeamMemberGroupedListProps {
  groups: TeamMemberGroup[]
  teamType: TeamCardType
  onDelete: (member: TeamMember) => void
  onEdit: (member: TeamMember) => void
  onSitesClicked: (member: TeamMember) => void
  isDisabled: (member: TeamMember) => boolean
}

function TeamMemberGroupedList(prop: TeamMemberGroupedListProps) {
  const { groups, teamType, onDelete, onEdit, onSitesClicked, isDisabled } = prop;
  return <Box display='flex' flexDirection='column' flexGrow={1}>
      {groups.map(group => <MemberGroup
        key={group.type}
        teamType={teamType}
        group={group}
        onDeleteClicked={member => onDelete(member)}
        onEditClicked={member => onEdit(member)}
        onSitesClicked={member => onSitesClicked(member)}     
        isDisabled={member => isDisabled(member)} 
      />)}
  </Box>;
}

interface TeamMemberUngroupedListProps {
  members: TeamMember[]
  onDelete: (member: TeamMember) => void
  onEdit: (member: TeamMember) => void
  onSitesClicked: (member: TeamMember) => void
  isDisabled: (member: TeamMember) => boolean
}

function TeamMemberUngroupedList(prop: TeamMemberUngroupedListProps) {
  const { members, onDelete, onEdit, onSitesClicked, isDisabled } = prop;
  const classes = useStyles()
  return <Box display='flex' flexDirection='column' flexGrow={1}>
    <Grid container direction='column' className={classes.ungroupedGrid}>
      {members
        .sort(compareParties)
        .map(member => <TeamMemberItem
          key={member.id}
          member={member}
          disabled={isDisabled(member)}
          onDeleteClicked={member => onDelete(member)}
          onEditClicked={member => onEdit(member)}
          onSitesClicked={member => onSitesClicked(member)}
      />)}
    </Grid>
  </Box>;
}

function getGroupSubtitle(teamType: TeamCardType, group: TeamMemberGroup, t: TFunction) {
  return t(`teamGroup${teamType}${group.type}Subtitle`, {count: group.members.length});
}

interface TeamMemberGroupProp {
  teamType: TeamCardType
  group: TeamMemberGroup
  onDeleteClicked: (member: TeamMember) => void
  onEditClicked: (member: TeamMember) => void
  onSitesClicked: (member: TeamMember) => void
  isDisabled: (member: TeamMember) => boolean
}

function MemberGroup(prop: TeamMemberGroupProp) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(prop.group.type === TeamMemberGroupType.SpecialMembers && prop.group.members.length > 0)

  return <Accordion
    elevation={0} // Otherwise the Accordion would look like a card on top of a card.
    className={classes.groupAccordion}
    square={true}
    expanded={expanded}
    onChange={(_event, expanded) => setExpanded(expanded)}
  >
    <AccordionSummary
      expandIcon={<ExpandMore/>}
      className={classes.groupAccordionSummary}
    >
      <Typography variant="subtitle2">{getGroupSubtitle(prop.teamType, prop.group, t)}</Typography>
    </AccordionSummary>
    <AccordionDetails className={classes.groupAccordionDetails}>
      <Grid container direction='column'>
        {expanded && prop.group.members.sort(compareParties)
            .map(member => <TeamMemberItem
              key={member.id}
              member={member}
              disabled={prop.isDisabled(member)}
              onDeleteClicked={prop.onDeleteClicked}
              onEditClicked={prop.onEditClicked}
              onSitesClicked={member => prop.onSitesClicked(member)}
            />)}
      </Grid>
    </AccordionDetails>
  </Accordion>
}

interface TeamMemberItemProp {
  member: TeamMember
  disabled: boolean
  onDeleteClicked: (member: TeamMember) => void
  onEditClicked: (member: TeamMember) => void
  onSitesClicked: (member: TeamMember) => void
}

function TeamMemberItem(prop: TeamMemberItemProp) {
  const member = prop.member
  const [showButtons, setShowButtons] = useState(false)
  const secondaryText = member.teamRole ? member.teamRole : member.jobTitle

  return (
    <Grid item
      onMouseEnter={(_event) => setShowButtons(true)}
      onMouseLeave={(_event) => setShowButtons(false)}
    >
      <Box display='flex' alignItems='center' px={2}>
        <Box display='flex' alignItems='center' flexDirection='row' flexGrow={1}>
          <Box display='flex' flexDirection='column' flexWrap='nowrap' py={1}>
            <Typography variant="subtitle1">{getFullName(member)}</Typography>
            <SecondaryText>{secondaryText}</SecondaryText>
          </Box>
        </Box>
        {member.isManuallyAdded && <Fade in={showButtons}>
          <Box display='flex' flexDirection='row'>
            <TIButton disabled={prop.disabled} onClick={() => prop.onEditClicked(member)} icon={<Edit/>} tooltipKey='teamMemberEditTooltip'/>
            <TIButton disabled={prop.disabled} onClick={() => prop.onDeleteClicked(member)} icon={<DeleteOutlined/>} tooltipKey='teamMemberDeleteTooltip'/>
          </Box>
        </Fade>}
        {!member.isManuallyAdded && <Box display='flex' flexDirection='row'>
          <TIBox tooltipKey='teamMemberLockedTooltip' icon={<LockOutlined/>} />
        </Box>}
        <Box display='flex'>
          <TIButton edge='end' disabled={prop.disabled} onClick={() => prop.onSitesClicked(member)} icon={<LocationCity/>} tooltipKey='teamMemberSitesTooltip' ariaLabel='sites'/>
        </Box>
      </Box>
    </Grid>
  )
}

interface AddTeamMemberDialogProp {
  open: boolean
  onAction: (party: Opt<Party>, role: Opt<string>) => void

  // If defined, then use the dialog to edit role of the member.
  member: Opt<TeamMember>

  excludePartyIds: Set<number>

  hasExternalId?: boolean
}

function AddTeamMemberDialog(prop: AddTeamMemberDialogProp) {
  const { t } = useTranslation()
  const [party, setParty] = useState<Opt<Party>>(prop.member)
  const [role, setRole] = useState<string>(prop.member?.teamRole ?? '')
  const editing = prop.member !== undefined

  const onClose = (cb: () => void) => {
    cb()

    // Clear selections in case dialog gets opened again.
    setParty(undefined)
    setRole('')
  }

  const onSave = () => onClose(() => prop.onAction(party, blankAsUndefined(role)))
  const onCancel = () => onClose(() => prop.onAction(undefined, undefined))

  return (
    <Dialog
      open={prop.open}
      onClose={onCancel}
      fullWidth={true}
      maxWidth='xs'
    >
      <DialogTitle>{t(editing ? 'teamDialogTitleEdit' : 'teamDialogTitleAdd')}</DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <PartySelectorItem
            label={t('teamDialogLabelParty')}
            initial={party ?? null}
            onChange={setParty}
            autoFocus={prop.member === undefined}
            hasExternalId={prop.hasExternalId}
            disabled={editing}
            excludePartyIds={prop.excludePartyIds}
          />
          <TextItem
            name='role'
            label={t('teamDialogLabelRole')}
            value={role}
            maxLength={255}
            onChange={event => setRole(event.target.value)}
            autoFocus={prop.member !== undefined}
            onEnterPressed={onSave}
            info={t('teamDialogRoleHelper')}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color='primary'>{t('buttonCancel')}</Button>
        <Button onClick={onSave} disabled={party == null} color='primary'>{t(editing ? 'buttonSave' : 'buttonAdd')}</Button>
      </DialogActions>
    </Dialog>
  )
}
