import React from 'react';
import { Backdrop, Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';

interface BackdropLoadingSpinnerProps {
  open: boolean;
  message: string;
}

const useStyles = makeStyles((theme) => ({
  spinnerContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: '1rem',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '200px',
  },
  backdrop: {
    zIndex: 1
  },
  spinner: {
    color: 'inherit'
  }
}));

export function BackdropLoadingSpinner( p: BackdropLoadingSpinnerProps ) {

  const classes = useStyles();

  return (
    <Backdrop open={p.open} className={classes.backdrop} data-testid='backdrop'>
      <Box className={classes.spinnerContainer}>
        <CircularProgress className={classes.spinner} data-testid='spinner'/>
        <Typography>{p.message}</Typography>
      </Box>
    </Backdrop>
  );
};
