import { GetApp } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { FileAttachment } from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import * as api from '../tools/API'
import { useSnackbar } from 'notistack'
import { getErrorMessage, handleDownload } from '../tools/Utils'
import { TIButton } from './SmallComponents'

export interface DownloadAttachmentButtonProp {
  file: FileAttachment
  preferSaveAs?: boolean
}

export function DownloadAttachmentButton(p: DownloadAttachmentButtonProp) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const preferSaveAs = p.preferSaveAs ?? true

  async function openAttachment() {
    try {
      const blob = await api.getFileAttachment(p.file.id)
      handleDownload(blob, p.file.filename, preferSaveAs)
    } catch (err) {
      console.log(`Error fetching blob: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchGeneric', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
  }

  return (
    <div>
      <TIButton
        icon={<GetApp/>}
        onClick={_event => openAttachment()}
        tooltipKey='fileDownloadTooltip'
      />
    </div>
  )
}
