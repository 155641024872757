import { useTranslation } from 'react-i18next'
import { searchCustomers } from '../tools/API'
import * as navigator from '../tools/Navigator'
import { BaseTable, ColumnDef } from './BaseTable'
import { useSetTitle } from '../tools/AppEvents'

const columns: ColumnDef[] = [
  { width: 15, field: 'externalId', headerTextId: 'customersHeaderExtId', sortBy: 'EXTERNAL_ID' },
  { width: 85, field: 'name', headerTextId: 'customersHeaderName', sortBy: 'NAME' },
]

export function CustomersTable() {
  const { t } = useTranslation()
  useSetTitle(t('drawerMenuCustomers'))

  return (
    <BaseTable
      tableId='customers'
      columns={columns}
      filterLabelId='customersSearchLabel'
      onOpenDetails={navigator.openCustomerDetails}
      onFetchItems={searchCustomers}
    />
  )
}
