import { TeamMember } from './API'

// Active/Inactive in code refer to user isActive, but "Active" in UI might mean e.g. "actively interested" == "Special" in code.
export enum TeamMemberGroupType {
  SpecialMembers = "Special", // This is currently only applicable to site teams
  ActiveMembers = "Active",
  InactiveMembers = "Inactive",
  ResponsibleParties = "Responsible",
}

export interface TeamMemberGroup {
  type: TeamMemberGroupType,
  members: TeamMember[]
}

export function splitToGroups(members: TeamMember[], responsibleParties: TeamMember[] = []) {
  let result: TeamMemberGroup[] = [];
  const specialMembers = members.filter(m => m.isSpecial === true)
  result.push({
    type: TeamMemberGroupType.SpecialMembers,
    members: specialMembers
  });
  result.push({
    type: TeamMemberGroupType.ResponsibleParties,
    members: responsibleParties
  });
  result.push({
    type: TeamMemberGroupType.ActiveMembers,
    members: members.filter(m => m.activeAccount === true && !specialMembers.includes(m))
  });
  result.push({
    type: TeamMemberGroupType.InactiveMembers,
    members: members.filter(m => m.activeAccount === false && !specialMembers.includes(m))
  });
  return result;
}
