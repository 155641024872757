import { useRef, useEffect, DependencyList } from 'react'
import { getErrorMessage, shallowEquals } from './Utils'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import * as snacky from '../components/CustomSnackbarProvider'

export function useShallowEqualsRef<T>(value: T) {
  // Based on https://stackoverflow.com/a/54096391
  const ref = useRef(value)

  if (!shallowEquals(ref.current, value)) {
    ref.current = value
  }

  return ref.current
}

export function useFetchData<T>(apiCall: () => Promise<T>, responseCallback: (response: T) => void, deps?: DependencyList) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  async function fetchData() {
    try {
      let response = await apiCall()
      responseCallback(response)
    } catch (err) {
      console.log(`Error fetching: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchGeneric', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
  }

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? [])
}

// Based on https://usehooks.com/useEventListener/
export function useEventListener(eventName: string, handler: (event: Event) => void, element = document) {
  // Create a ref that stores handler
  const savedHandler = useRef<(event: Event) => void>()

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: Event) => {
        if (savedHandler.current) savedHandler.current(event)
      }

      // Add event listener
      element.addEventListener(eventName, eventListener)

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, element] // Re-run if eventName or element changes
  )
}
