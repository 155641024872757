import { Party } from "./API"

export function getFullName(party: Party) {
  let firstname = party.firstname?.trim()
  let lastname = party.lastname?.trim()
  if (firstname?.length === 0) firstname = undefined
  if (lastname?.length === 0) lastname = undefined

  if (firstname && lastname) {
    return `${lastname}, ${firstname}`
  } else {
    return lastname ?? firstname
  }
}

export function compareParties(a: Party, b: Party) {
  let k = (a.lastname ?? '').localeCompare(b.lastname ?? '')

  if (k === 0) {
    k = (a.firstname ?? '').localeCompare(b.firstname ?? '')
  }

  if (k === 0) {
    k = a.id - b.id
  }

  return k
}