import { DateTime } from 'luxon'
import { truncateToDate, LocalDate, daysDistance, Opt } from './Utils'
import { SiteEvaluationEvent } from './API'

export interface SiteEvaluationEventGroupSpec {
  key: string
  labelId: string
  plannedDayOffset?: number
}

const GROUP_SPECS: SiteEvaluationEventGroupSpec[] = [
  // These must be in order from earliest to latest/farthest.
  { key: 'lat', labelId: 'siteEvaluationEventLate',    plannedDayOffset: 0 },
  { key: 'cur', labelId: 'siteEvaluationEventCurrent', plannedDayOffset: 1 },
  { key: 'fut', labelId: 'siteEvaluationEventFuture' },
]

export interface SiteEvaluationEventGroup {
  spec: SiteEvaluationEventGroupSpec
  events: SiteEvaluationEvent[]
}

export function splitToGroups(events: SiteEvaluationEvent[], ref?: Opt<LocalDate>) {
  let currentGroupIndex = 0
  let currentGroup = GROUP_SPECS[currentGroupIndex]
  let groupEvents: SiteEvaluationEvent[] = []
  let result: SiteEvaluationEventGroup[] = []
  ref = ref ?? truncateToDate(DateTime.now())

  for (const e of events) {
    const d = daysDistance(e.plannedDate, ref as LocalDate)

    while (currentGroup.plannedDayOffset !== undefined && d >= currentGroup.plannedDayOffset) {

      if (groupEvents.length > 0) {
        result.push({ spec: currentGroup, events: groupEvents })
        groupEvents = []
      }

      currentGroupIndex += 1
      currentGroup = GROUP_SPECS[currentGroupIndex]
    }

    groupEvents.push(e)
  }

  if (groupEvents.length > 0) {
    result.push({ spec: currentGroup, events: groupEvents })
  }

  return result
}
