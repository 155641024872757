import { Opt } from '../tools/Utils'
import { Grid, TextField, TextFieldProps } from '@material-ui/core'

interface TimeItemProp {
  time?: Opt<string>
  onChanged: (time?: string) => void
}

export function TimeItem(p: TimeItemProp & TextFieldProps) {
  const {time, onChanged, ...rest} = p

  return (
    <Grid item>
      <TextField
        {...rest}
        type='time'
        variant='outlined'
        value={time}
        onChange={event => p.onChanged(event.target.value)}
        InputLabelProps={{
          shrink: true // Without this the label overlaps with "dd / mm / yyyy" text (label isn't positioned on the top edge)
        }}
      />
    </Grid>
  )
}
