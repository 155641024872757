import { DateTime } from 'luxon'
import { truncateToDate, LocalDate, daysDistance, Opt } from './Utils'
import { CalendarEvent } from './API'

export interface CalendarEventGroupSpec {
  key: string
  labelId: string
  fromDayOffset?: number
  toDayOffset?: number
}

const GROUP_SPECS: CalendarEventGroupSpec[] = [
  // These must be in order from earliest to latest/farthest.
  { key: 'pst', labelId: 'siteCalendarPast',                       toDayOffset: 0 },
  { key: 'tod', labelId: 'siteCalendarToday',    fromDayOffset: 0, toDayOffset: 1 },
  { key: 'tom', labelId: 'siteCalendarTomorrow', fromDayOffset: 1, toDayOffset: 2 },
  { key: 'lat', labelId: 'siteCalendarLater',    fromDayOffset: 2 },
]

export interface CalendarEventGroup {
  spec: CalendarEventGroupSpec
  events: CalendarEvent[]
}

export function splitToGroups(events: CalendarEvent[], ref?: Opt<LocalDate>) {
  let currentGroupIndex = 0
  let currentGroup = GROUP_SPECS[currentGroupIndex]
  let groupEvents: CalendarEvent[] = []
  let result: CalendarEventGroup[] = []
  ref = ref ?? truncateToDate(DateTime.now())

  for (const e of events) {
    // TODO: fix to work with start + end...
    const d1 = daysDistance(e.eventStart, ref as LocalDate)
    const d2 = daysDistance(e.eventEnd, ref as LocalDate)
    const d = Math.abs(d1) < Math.abs(d2) ? d1 : d2

    while (currentGroup.toDayOffset !== undefined && d >= currentGroup.toDayOffset) {

      if (groupEvents.length > 0) {
        result.push({ spec: currentGroup, events: groupEvents })
        groupEvents = []
      }

      currentGroupIndex += 1
      currentGroup = GROUP_SPECS[currentGroupIndex]
    }

    groupEvents.push(e)
  }

  if (groupEvents.length > 0) {
    result.push({ spec: currentGroup, events: groupEvents })
  }

  return result
}
