import { BaseTable, ColumnDef } from './BaseTable'
import { useTranslation } from 'react-i18next'
import * as api from '../tools/API'
import * as navigator from '../tools/Navigator'
import { useSetTitle } from '../tools/AppEvents'

export interface SiteContractLinesTableProp {
  siteId: number
}

const columns: ColumnDef[] = [
  { field: 'contract.externalId', headerTextId: 'linesHeaderContractExtId' },
  { width: 15, field: 'lineNumber', headerTextId: 'linesHeaderLineNumber' },
  { field: 'serviceCode', headerTextId: 'linesHeaderServiceCode' },
  { width: 30, field: 'name', headerTextId: 'linesHeaderName' },
  { width: 15, field: 'isActive', headerTextId: 'linesHeaderActive' },
]

export function SiteContractLinesTable(p: SiteContractLinesTableProp) {
  const { t } = useTranslation()

  // 'site' is only provided when opening this table via site details. It is not available when user enters the URL directly.
  const siteName = ((navigator.getCurrentState() ?? {}) as api.Site)?.name
  const title = siteName ? t('siteContractLinesTableTitleWithName', {name: siteName}) : t('siteContractLinesTableTitle')

  useSetTitle(title)

  return (
    <BaseTable
      tableId='contractLines'
      columns={columns}
      onFetchItems={args => api.listContractsLinesBySiteId(p.siteId, args)}
      overrideArgs={{page: 0}}
    />
  )
}
