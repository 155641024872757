import React, { useState } from 'react'
import { AppBar, MenuItem, Toolbar, Typography, Menu, makeStyles, PopoverProps, Box } from '@material-ui/core'
import * as icon from '@material-ui/icons'
import { drawerWidth, drawerMobileBreakpoint } from './ResponsiveDrawer'
import { useTranslation } from 'react-i18next'
import * as navigator from '../tools/Navigator'
import * as http from '../tools/Http'
import { dispatchAppEvent } from '../tools/AppEvents'
import { TIButton } from './SmallComponents'
import { isStagingEnv, isDevEnv } from '../tools/Utils'
import { useAppEventListener } from '../tools/AppEvents'

const useStyles = makeStyles(theme => (
  {
    appBar: {
      [theme.breakpoints.up(drawerMobileBreakpoint)]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      backgroundColor: appBarColor().background,
      color: appBarColor().foreground,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(drawerMobileBreakpoint)]: {
        display: 'none',
      },
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
  }
))

export enum ToolBarMenuAction {
  DetailsExpandAll,
  DetailsCollapseAll,
  ExportToExcel
}

export interface AppToolBarProp {
  onToggleDrawer: () => void
  showBackButton: boolean
  validMenuActions: ToolBarMenuAction[]
}

export function AppToolBar(prop: AppToolBarProp) {
  const classes = useStyles()
  const [title, setTitle] = useState('')

  // Title can only be updated using the corresponding event. This way we can update the title once
  // data has been fetched (e.g. site name), and title can be set within the view element itself. The
  // initial idea was based on navigation NaviState -> had to re-navigator to the view to update
  // the title.
  useAppEventListener('UpdateTitle', (_type, data) => setTitle(data?.title ?? ''))

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={4}>
      <Toolbar>
          <TIButton
            tooltipKey="buttonMenuTooltip"
            color="inherit"
            edge="start"
            onClick={prop.onToggleDrawer}
            className={classes.menuButton}
            icon={<icon.Menu/>}
          />
          { prop.showBackButton &&
          <TIButton
            tooltipKey="buttonBackTooltip"
            color="inherit"
            edge="start"
            onClick={navigator.back}
            className={classes.backButton}
            icon={<icon.ArrowBack/>}
          />
          }
          <Box display='flex' flexDirection='row' flexGrow={1} justifyContent='left'>
            <Typography variant="h6" noWrap>{title}</Typography>
          </Box>
          { prop.validMenuActions?.length > 0 && <ContextMenuButton validMenuActions={prop.validMenuActions}/> }
          <UserMenuButton/>
      </Toolbar>
    </AppBar>
  )
}

function UserMenuButton() {
  const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl']>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => setAnchorEl(null)
  const { t } = useTranslation()

  function handleLogout() {
    handleClose()
    http.logout()
    navigator.replaceWithNextOr(navigator.PATH_SIGNED_OUT)
  }

  return (
    <React.Fragment>
      <TIButton
        tooltipKey='buttonMenuTooltip'
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={event => setAnchorEl(event.currentTarget)}
        color='inherit'
        icon={<icon.AccountCircle />}
      />
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disabled={true}>{http.currentAccountName()}</MenuItem>
        <MenuItem onClick={handleLogout}>{t('buttonLogout')}</MenuItem>
      </Menu>
    </React.Fragment>
  )
}

function ContextMenuButton({validMenuActions}: {validMenuActions: ToolBarMenuAction[]}) {
  const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl']>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => setAnchorEl(null)
  const { t } = useTranslation()

  function setExpandedState(expanded: boolean) {
    dispatchAppEvent(expanded ? "DetailsExpandAll" : "DetailsCollapseAll")
    handleClose()
  }

  function sendExportEvent() {
    dispatchAppEvent('ExportToExcel')
    handleClose()
  }

  function showAction(action: ToolBarMenuAction): boolean {
    return validMenuActions?.includes(action)
  }

  return (
    <React.Fragment>
      <TIButton
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={event => setAnchorEl(event.currentTarget)}
        color='inherit'
        icon={<icon.MoreVert />}
        tooltipKey='buttonMenuTooltip'
      />
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        { showAction(ToolBarMenuAction.DetailsExpandAll) && <MenuItem onClick={() => setExpandedState(true)}>{t('detailsButtonExpandAll')}</MenuItem> }
        { showAction(ToolBarMenuAction.DetailsCollapseAll) && <MenuItem onClick={() => setExpandedState(false)}>{t('detailsButtonCollapseAll')}</MenuItem> }
        { showAction(ToolBarMenuAction.ExportToExcel) && <MenuItem onClick={() => sendExportEvent()}>{t('exportToExcelButton')}</MenuItem> }
      </Menu>
    </React.Fragment>
  )
}

// TODO Cannot figure out how to make an popup menu (button) element that would just take the menu items as parameters (MUI examples use similar code, i.e. no generic menu is used). How to

function appBarColor(): AppBarColor {
  if (isStagingEnv) {
    return {background: '#E84754', foreground: '#FFFFFF'}
  } else if (false && isDevEnv) { // TODO temporary only, remove once tested enough
    return {background: '#16D0A5', foreground: '#FFFFFF'}
  } else {
    return {background: '#FFFFFF', foreground: '#000000'}
  }
}

interface AppBarColor {
  foreground: string
  background: string
}
