import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { SupervisedUserCircleOutlined, FolderSpecialOutlined, RestoreOutlined, FolderOutlined } from '@material-ui/icons'
import { SiteStatusCard } from './SiteStatusCard'
import * as navigator from '../tools/Navigator'
import * as api from '../tools/API'
import { Site, FileAttachment } from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import { useSnackbar } from 'notistack'
import { TeamCard, TeamCardType } from './TeamCard'
import { LinkCard } from './LinkCard'
import { FilesCard } from './FilesCard'
import { CustomerInfoCard } from './CustomerInfoCard'
import { ServiceDescriptionListCard } from './ServiceDescriptionCard'
import { CalendarEventsCard } from './CalendarEventsCard'
import { getErrorMessage } from '../tools/Utils'
import { useSetTitle } from '../tools/AppEvents'
import { SiteEvaluationEventsCard } from './SiteEvaluationEventsCard'
import { SiteInfoCard } from './SiteInfoCard'
import { SitePriceListCard } from './SitePriceListCard'
import { BackdropLoadingSpinner } from './BackdropLoadingSpinner'

interface SiteDetailsState {
  site?: Site
}

interface SiteDetailsProp {
  siteId: number
}

export function SiteDetailsView(prop: SiteDetailsProp) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [site, setSite] = useState(((navigator.getCurrentState() ?? {}) as SiteDetailsState).site)
  const [loadingSite, setLoadingSite] = useState<boolean>(false)
  
  async function loadSite() {
    try {
      setLoadingSite(true)
      let site = await api.getSiteBasicInfoById(prop.siteId)
      setSite(site)
      setLoadingSite(false)
    } catch (err) {
      console.log(`Error fetching: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchSiteDetails', {message: getErrorMessage(err)}), snacky.errorOpts)
      setLoadingSite(false)
    }
  }

  useEffect(() => {
    // Site search results do not include 'files'.
    if (site === undefined || site.files === undefined) {
      loadSite()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useSetTitle(site?.name ?? '')

  return (
    <Grid container spacing={3} direction="row">
      <BackdropLoadingSpinner open={loadingSite} message={t('siteLoadingMsg')} />
      <Grid item xs={12} sm={12} md={6}>
        <Grid container spacing={2} direction="column" >
          <Grid item>
            <SiteInfoCard site={site}/>
          </Grid>
          <Grid item>
            <SiteStatusCard site={site}/>
          </Grid>
          <Grid item>
            <SiteFilesCard siteId={prop.siteId} files={site?.files ?? []}/>
          </Grid>
          <Grid item>
            <CalendarEventsCard siteId={prop.siteId}/>
          </Grid>
          <Grid item>
            <SiteEvaluationEventsCard siteId={prop.siteId}/>
          </Grid>
          <Grid item>
            <ServiceDescriptionListCard siteId={prop.siteId}/>
          </Grid>
          {/* To be implemented */}
          {/*<Grid item>
            <DetailsCard cardId='siteIns' title={t('siteInstructionsTitle')} subtitle={t('siteInstructionsSubtitle', {count: 2})} icon={<AssignmentOutlined/>}><div></div></DetailsCard>
          </Grid>*/}
          <Grid item>
            <TeamCard
              type={TeamCardType.ServiceTeam}
              ownerId={site?.id}
              title={t('siteServiceTeamTitle')}
              icon={<SupervisedUserCircleOutlined/>}
              grouped={true}
              onSitesClick={member => navigator.push(`/party/${member.id}/sites`, {title: t('logTableTitle', {name: member.firstname})})}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Grid container spacing={2} direction="column" >
          <Grid item>
            <CustomerInfoCard customer={site?.customer} showLink={true}/>
          </Grid>
          <Grid item>
            <TeamCard
              type={TeamCardType.SiteOthers}
              ownerId={site?.id}
              title={t('siteOtherContactsTitle')}
              icon={<FolderSpecialOutlined/>}
              grouped={false}
              onSitesClick={member => navigator.push(`/party/${member.id}/sites`, {title: t('logTableTitle', {name: member.firstname})})}
              />
          </Grid>
          {/* Commented out temporarily until access control mechanisms etc are properly specified */ }
          {/*<Grid item>
            <SiteFormCard siteId={site?.id}/>
          </Grid>*/}
          <Grid item>
            <LinkCard
              cardId='siteLog'
              title={t('logCardTitle')}
              icon={<RestoreOutlined/>}
              onClick={() => navigator.push(`/site/${site!.id}/log`, {title: t('logTableTitle', {name: site!.name})})}
            />
          </Grid>
          <Grid item>
            <LinkCard
              cardId='contractLines'
              title={t('siteContractLinesCardTitle')}
              icon={<FolderOutlined/>}
              onClick={() => navigator.push(`/site/${site!.id}/contract-lines`, site)}
            />
          </Grid>
          <Grid item>
            <SitePriceListCard site={site}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

interface SiteFilesCardProp {
  siteId: number
  files: FileAttachment[]
}

function SiteFilesCard(p: SiteFilesCardProp) {
  const { t } = useTranslation()

  return (
    <FilesCard
      cardId='siteFiles'
      title={t('siteAttachmentsTitle')}
      grouped={true}
      files={p.files}
      fileSaver={(file, description, category) => api.addSiteFile(p.siteId, file, description, category)}
      fileUpdater={(fileId, description, category) => api.updateSiteFile(p.siteId, fileId, description, category)}
      siteId={p.siteId}
    />
  )
}
