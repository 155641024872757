import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as http from './tools/Http'
//import reportWebVitals from './reportWebVitals';
import history from 'history/browser'
import { initI18n } from './tools/Strings'
import { checkDisableConsoleMessages } from './tools/Utils'
import { CustomSnackbarProvider } from './components/CustomSnackbarProvider'
import { ThemeProvider } from '@material-ui/core/styles'

// Use the unstable version for now to avoid warnings about using the deprecated findDOMNode + StrictMode.
// See https://stackoverflow.com/a/64135466 for more details.
import { CssBaseline, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core'

checkDisableConsoleMessages()
initI18n(getLanguage())
http.setAcceptLanguage(getLanguage())

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#395381',
      main: '#002b54',
      dark: '#00002b',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#69b3ff',
      main: '#0084ff',
      dark: '#0058cb',
      contrastText: '#ffffff',
    },
    background: {
      default: "#f3f3f3"
    }
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
      }
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <CustomSnackbarProvider>
        <App />
      </CustomSnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

function getLanguage() {
  const supportedLanguages = ['fi', 'sv', 'en']
  const defaultLanguage = 'fi'
  const lang = (new URLSearchParams(history.location.search)).get('lang') ?? defaultLanguage
  return supportedLanguages.indexOf(lang) < 0 ? defaultLanguage : lang
}
