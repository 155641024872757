import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Backdrop, CircularProgress, Theme, makeStyles } from '@material-ui/core'

import * as api from '../tools/API'
import * as navigator from '../tools/Navigator'
import { useSetTitle } from '../tools/AppEvents'
import { TabbedPanel, TabbedPanelTabProp } from './TabbedPanel'
import { getErrorMessage, handleDownload } from '../tools/Utils'
import * as snacky from './CustomSnackbarProvider'
import { PriceListLinesTable } from './PriceListLinesTable'
import { PriceListDetailsPanel } from './PriceListDetailsPanel'

export interface SitePriceListViewProp {
  contractId: number
}

const useTableStyles = makeStyles((theme: Theme) => (
  {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    }
  }
))

export function SitePriceListView(prop: SitePriceListViewProp) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const classes = useTableStyles();
  const navigatorState = (navigator.getCurrentState() ?? {}) as {contractId: number, site: api.Site}
  const [priceList, setPriceList] = useState<api.PriceList>()
  const [inProgress, setInProgress] = useState(false)

  async function doExport(args: api.SearchArgs, extraArgs?: any) {
    setInProgress(true)
    const includeZeroPriced = extraArgs?.includeZeroPriced ?? false
    try {
      const blob = await api.exportContractPriceList(prop.contractId, includeZeroPriced, args.searchTerm)
      handleDownload(blob, 'price-list-export.xlsx', true)
    } catch (err) {
      enqueueSnackbar(t('errExport', {message: err}), snacky.errorOpts)
    } finally {
      setInProgress(false)
    }
  }

  useEffect(() => {
    async function loadPriceList() {
      setInProgress(true)
      try {
        const result = await api.getContractPriceListById(prop.contractId)
        setPriceList(result)
        return result
      } catch (err) {
        console.log(`Error fetching: ${JSON.stringify(err)}`)
        enqueueSnackbar(t('errFetchPriceListDetails', {message: getErrorMessage(err)}), snacky.errorOpts)
      } finally {
        setInProgress(false)
      }
    }

    loadPriceList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop.contractId])

  const priceListTabs: TabbedPanelTabProp[] = [
    {
      label: t('sitePriceListDetailsTabLabel'),
      child: <PriceListDetailsPanel priceList={priceList} inProgress={inProgress} />
    },
    {
      label: t('sitePriceListTabLabel'),
      child: <PriceListLinesTable
                contractId={prop.contractId}
                onExport={doExport}
              />
    }
  ]

  const site = navigatorState.site
  // 'site' is only provided when opening this table via site details. It is not available when user enters the URL directly.
  const title = site
    ? t('sitePriceListViewTitleWithName', {priceListName: priceList?.contractExternalId, customerName: site.customer?.name , siteAddress: site.streetAddress})
    : t('sitePriceListViewTitle', {priceListName: priceList?.contractExternalId})

  useSetTitle(title)

  return (
    <>
      <TabbedPanel tabs={priceListTabs} />
      <Backdrop className={classes.backdrop} open={inProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
