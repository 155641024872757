import { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { EmojiPeople, LocationCityOutlined, SupervisedUserCircleOutlined, } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import * as navigator from '../tools/Navigator'
import * as api from '../tools/API'
import { Customer, FileAttachment } from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import { useSnackbar } from 'notistack'
import { TeamCard, TeamCardType } from './TeamCard'
import { FilesCard } from './FilesCard'
import { CustomerInfoCard } from './CustomerInfoCard'
import { LinkCard } from './LinkCard'
import { getErrorMessage } from '../tools/Utils'
import { useSetTitle } from '../tools/AppEvents'
import { CustomerOwnPartiesCard } from './CustomerOwnPartiesCard'
import { BackdropLoadingSpinner } from './BackdropLoadingSpinner'

interface CustomerDetailsState {
  customer?: Customer
}

interface CustomerDetailsProp {
  customerId: number
}

export function CustomerDetailsView(prop: CustomerDetailsProp) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [customer, setCustomer] = useState(((navigator.getCurrentState() ?? {}) as CustomerDetailsState).customer)
  const [loadingCustomer, setLoadingCustomer] = useState<boolean>(false)

  async function loadCustomer() {
    try {
      setLoadingCustomer(true)
      let c = await api.getCustomerById(prop.customerId)
      setCustomer(c)
      setLoadingCustomer(false)
    } catch (err) {
      console.log(`Error fetching: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchGeneric', {message: getErrorMessage(err)}), snacky.errorOpts)
      setLoadingCustomer(false)
    }
  }

  useEffect(() => {
    if (customer === undefined || customer.files === undefined) {
      loadCustomer()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useSetTitle(customer?.name ?? '')

  return (
    <Grid container spacing={3} direction="row">
     <BackdropLoadingSpinner open={loadingCustomer} message={t('customerLoadingMsg')}/>
      <Grid item xs={12} sm={12} md={6}>
        <Grid container spacing={2} direction="column" >
          <Grid item>
            <CustomerInfoCard customer={customer} showLink={false}/>
          </Grid>
          <Grid item>
            <LinkCard
              cardId='customerSites'
              icon={<LocationCityOutlined/>}
              title={t('customerSitesCardTitle')}
              onClick={() => navigator.push(`/customer/${customer!.id}/sites`, {title: t('customerSitesTableTitle', {name: customer!.name})})}
            />
          </Grid>
          <Grid item>
            <TeamCard
              type={TeamCardType.CustomerTeam}
              ownerId={customer?.id}
              title={t('customerTeamTitle')}
              icon={<SupervisedUserCircleOutlined/>}
              grouped={false}
              onSitesClick={member => navigator.push(`/party/${member.id}/sites`, {title: t('logTableTitle', {name: member.firstname})})}    
              />
          </Grid>
          <Grid item>
            <CustomerOwnPartiesCard
              customerId={customer?.id}
              title={t('customerPartiesCardTitle')}
              icon={<EmojiPeople/>}
              onSitesClick={party => navigator.push(`/party/${party.id}/sites`, {title: t('logTableTitle', {name: party.firstname})})}    
              />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Grid container spacing={2} direction="column" >
          <Grid item>
            <CustomerFilesCard customerId={prop.customerId} files={customer?.files ?? []}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

interface CustomerFilesCardProp {
  customerId: number
  files: FileAttachment[]
}

function CustomerFilesCard(p: CustomerFilesCardProp) {
  const { t } = useTranslation()

  return (
    <FilesCard
      cardId='customerFiles'
      title={t('customerAttachmentsTitle')}
      files={p.files}
      fileSaver={(file, description) => api.addCustomerFile(p.customerId, file, description)}
      fileUpdater={(fileId, description) => api.updateFileAttachment(fileId, description)}
    />
  )
}
