import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, makeStyles } from '@material-ui/core'
import { Add, ExpandMore } from '@material-ui/icons'
import { SecondaryText, TIButton } from './SmallComponents';
import { useAppEventListener } from '../tools/AppEvents'
import { useEffect } from 'react';
import { isDefined } from '../tools/Utils';

export interface TitleRowProp {
  title: string
  icon?: React.ReactNode
  subtitle?: string
  auxButton?: JSX.Element
  onAddClicked?: () => void // Shortcut for using AddButton
  expanded?: boolean
  addTooltipKey?: string
  addButtonEdge?: false|'start'|'end'
  disabled?: boolean
}

export interface DetailsCardProp extends TitleRowProp {
  cardId: string
  children: JSX.Element | JSX.Element[] | undefined | null | false
  noPadContent?: boolean
  /** Callback to run when the card is first expanded (either by the user, due to properties value, or saved state) */
  onFirstExpansion?: () => void
  /** Callback to run when the card is initially in collapsed state */
  onInitIfCollapsed?: () => void
  /** Callback to run when the card is being set up */
  onInit?: () => void
  disabled?: boolean
}

export const useDetailCardStyles = makeStyles(_theme => (
  {
    npcDetails: {
      padding: 0
    },
    summary: {
      minHeight: '5.2em'
    },
    details: {
      wordBreak: 'break-word'
    }
  }
))

export interface DetailsButtonProp {
  onClick: () => void
  icon: React.ReactNode
  tooltipKey?: string
  edge?: false|'start'|'end'
  disabled?: boolean
}

export function DetailsButton(p: DetailsButtonProp) {
  return (
    <TIButton
      disabled={p.disabled}
      edge={p.edge}
      tooltipKey={p.tooltipKey}
      icon={p.icon}
      onClick={event => {
        event.stopPropagation() // Prevent expand/collapse action from getting triggered.
        p.onClick()
      }}
    />
  )
}

export interface AddButtonProp {
  onClick: () => void
  tooltipKey?: string
  edge?: false|'start'|'end'
  disabled?: boolean
}

export function AddButton(p: AddButtonProp) {
  return <DetailsButton edge={p.edge} onClick={p.onClick} tooltipKey={p.tooltipKey} icon={<Add/>} disabled={p.disabled}/>
}

export function DetailsCard(prop: DetailsCardProp) {
  const {onFirstExpansion, onInitIfCollapsed, onInit} = {...prop}
  const expKey = `${prop.cardId}-expanded`
  const [expanded, setExpanded] = useState(prop.expanded ?? localStorage.getItem(expKey) !== null)
  const [hasBeenExpanded, setHasBeenExpanded] = useState(false)
  const [onInitCalled, setOnInitCalled] = useState(false)
  const classes = useDetailCardStyles()

  useEffect(() => {
    if (!hasBeenExpanded) {
      if(isDefined(onFirstExpansion) && expanded) {
        onFirstExpansion()
        setHasBeenExpanded(true)
      } else if(isDefined(onInitIfCollapsed) && !onInitCalled && !expanded) {
        onInitIfCollapsed()
        setOnInitCalled(true)
      }
    }
  }, [hasBeenExpanded, expanded, onInitCalled, onFirstExpansion, onInitIfCollapsed])

  useEffect(() => {
    if (!!onInit && !onInitCalled) {
      onInit()
      setOnInitCalled(true)
    }
  }, [onInit, onInitCalled])

  // Initially thought that expand|collapsed functionality could be disabled if the card has no children.
  // Defining a value (true|false) to Accordion.expanded disables the functionality.
  // For example while loading the data to card. However, that makes the element controlled and seems
  // that changing a controlled element to uncontrolled isn't allowed (and it breaks the expand functionality).

  function handleExpanded(expanded: boolean) {
    // Persist expanded status so that same cards are expanded by default when entering details view.
    // Could aid in finding the information the user is looking for. For example user doesn't use the
    // app often and is only interested in the info in certain cards.
    if (expanded) {
      localStorage.setItem(expKey, 'true')
    } else {
      localStorage.removeItem(expKey)
    }
    setExpanded(expanded)
  }

  useAppEventListener("DetailsCollapseAll", _type => handleExpanded(false))
  useAppEventListener("DetailsExpandAll", _type => handleExpanded(true))

  return (
    <Accordion
      expanded={expanded && !prop.disabled}
      onChange={(_event, expanded) => handleExpanded(expanded)}
      disabled={prop.disabled}
    >
      <AccordionSummary
        expandIcon={prop.children && <ExpandMore/>}
        className={classes.summary}
      >
        <TitleRow {...prop} />
      </AccordionSummary>
      <AccordionDetails
        className={`${classes.details} ${prop.noPadContent ? classes.npcDetails : undefined}`}
      >
        {prop.children}
      </AccordionDetails>
    </Accordion>
  )
}

export function TitleRow(prop: TitleRowProp) {
  return (
    <Box display='flex' flexDirection='row' flexGrow={1} alignItems='center' flexWrap='nowrap'>
      { prop.icon && <Box mr={2}>{prop.icon}</Box> }

      <Box display='flex' flexDirection='column' flexGrow={1}>
        <Typography variant="h6">{prop.title}</Typography>
        { prop.subtitle && <SecondaryText>{prop.subtitle}</SecondaryText> }
      </Box>
      {
        prop.auxButton
      }
      { isDefined(prop.onAddClicked) &&
        <AddButton edge={prop.addButtonEdge} onClick={prop.onAddClicked} tooltipKey={prop.addTooltipKey} disabled={prop.disabled}/>
      }
    </Box>
  )
}
