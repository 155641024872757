import { useEffect, useCallback, DependencyList } from 'react'

type CallbackFunctionVariadicAnyReturn = (...args: any[]) => any

// Based on https://stackoverflow.com/a/61127960
export const useDebouncedEffect = (effect: CallbackFunctionVariadicAnyReturn, delay: number, deps: DependencyList) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(callback, delay);

    return () => {
      clearTimeout(handler)
    }
  }, [callback, delay])
}

export const useDebouncedCallback = (value: any, callback: (value: any) => void, delay: number) => {
  useDebouncedEffect(() => {
    callback(value)
  }, delay, [value])
}
