import { useTranslation } from "react-i18next"
import { Party } from "../tools/API"
import { useSnackbar } from 'notistack'
import { useState } from "react"
import { getErrorMessage, isDefined, subtitleHandler } from "../tools/Utils"
import * as api from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import { DetailsCard } from "./DetailsCard"
import { Grid, Box, Typography, makeStyles } from "@material-ui/core"
import { LocationCity } from "@material-ui/icons"
import { compareParties, getFullName } from "../tools/AppUtils"
import { SecondaryText, TIButton } from "./SmallComponents"

export interface CustomerOwnPartiesCardProperties {
    customerId?: number
    onSitesClick: (party: Party) => void
    icon: React.ReactNode
    title: string
}

const useStyles = makeStyles(theme => (
  {
    ungroupedGrid: {
      paddingBottom: '16px'
    }
  }
))

export function CustomerOwnPartiesCard(p: CustomerOwnPartiesCardProperties) {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [inProgress, setInProgress] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [parties, setParties] = useState<Party[]>([])
    const [partiesCount, setPartiesCount] = useState<number>(0)

    async function fetchParties() {
        try {
            setInProgress(true)
            const parties = await api.listCustomerOwnParties(p.customerId!)

            setInProgress(false)

            setParties(parties)
            setPartiesCount(parties.length)
        } catch (err) {
            console.log(`Error fetching: ${JSON.stringify(err)}`)
            enqueueSnackbar(t('errFetchGeneric', { message: getErrorMessage(err) }), snacky.errorOpts)
            setHasError(true)
            setInProgress(false)
        }
    }

    return (
        <DetailsCard
            cardId={`customer-parties`}
            title={p.title}
            noPadContent={true}
            subtitle={
              subtitleHandler({
                inProgress, 
                hasError, 
                loadingText:t('teamCardLoading'), 
                subtitleText:t('teamSubtitle', {count: partiesCount}), 
                errorMsg:t('errFetchGenericNoDetails')
              })
            }
            icon={p.icon}
            onFirstExpansion={isDefined(p.customerId) ? fetchParties : undefined}
            onInit={isDefined(p.customerId) ? fetchParties : undefined}
        >
        <PartyList
          parties={parties}
          onSitesClicked={party => p.onSitesClick(party)}
        />
        </DetailsCard>
    )
}

interface PartyListProps {
  parties: Party[]
  onSitesClicked: (party: Party) => void
}

function PartyList(prop: PartyListProps) {
  const { parties, onSitesClicked } = prop;
  const classes = useStyles()
  return <Box display='flex' flexDirection='column' flexGrow={1}>
    <Grid container direction='column' className={classes.ungroupedGrid}>
      {parties
        .sort(compareParties)
        .map(party => <PartyListItem
          key={party.id}
          party={party}
          onSitesClicked={party => onSitesClicked(party)}
      />)}
    </Grid>
  </Box>;
}

interface PartyListItemProp {
  party: Party
  onSitesClicked: (party: Party) => void
}

export function PartyListItem(prop: PartyListItemProp) {
  const party = prop.party

  return (
    <Grid item>
      <Box display='flex' alignItems='center' px={2}>
        <Box display='flex' alignItems='center' flexDirection='row' flexGrow={1}>
          <Box display='flex' flexDirection='column' flexWrap='nowrap' py={1}>
            <Typography variant="subtitle1">{getFullName(party)}</Typography>
            <SecondaryText>{party.jobTitle}</SecondaryText>
          </Box>
        </Box>
        <Box display='flex'>
          <TIButton edge='end' onClick={() => prop.onSitesClicked(party)} icon={<LocationCity/>} tooltipKey='teamMemberSitesTooltip' ariaLabel='sites'/>
        </Box>
      </Box>
    </Grid>
  )
}
