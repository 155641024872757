import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, makeStyles, Box } from '@material-ui/core'
import { TFunction, useTranslation } from 'react-i18next'

import * as api from '../tools/API'
import { formatAsLocalDate } from '../tools/Strings'
import { isDefined } from '../tools/Utils'
import { useEffect, useState } from 'react'

function mapValue(t: TFunction<"translation">, field: string, value: any) {
  if (field === 'hasExtraHoursDenied' || field === 'hasRoundedHoursDenied' || field === 'isActive') {
    return value ? t('genericYes') : t('genericNo')
  } else if (field === 'multiplier' || field.endsWith('Margin')) {
    return t('genericDecimalPercentage', {value: value})
  } else if (field === 'validFrom' || field === 'validTo') {
    return formatAsLocalDate(value)
  } else if (field === 'vatCode') {
    return t(`priceListVatCode_${value}`)
  }
  return value
}

const useStyles = makeStyles(theme => (
  {
    container: {
      display: 'flex',
      justifyContent: 'center'
    },
    table: {
      maxWidth: '40em',
    },
  }
))

const nonVisibleFields = ['contractExternalId']

interface PriceListDetailsPanelProp {
  priceList?: api.PriceList
  inProgress: boolean
}

export function PriceListDetailsPanel(prop: PriceListDetailsPanelProp) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [priceList, setPriceList] = useState(prop.priceList)
  const [hasData, setHasData] = useState(true)

  const items = Object.keys(priceList ?? {}).filter(key => key !== 'lines').map(key => (
    {
      label: key,
      value: mapValue(t, key, (priceList as any)[key])
    }
  ))

  useEffect(() => {
    if (!prop.inProgress && !isDefined(prop.priceList)) {
      setHasData(false)
    } else {
      setPriceList(prop.priceList)
      setHasData(true)
    }
  }, [prop])

  return (
    <Box className={classes.container}>
      {!hasData ? t('priceListDataNotAvailable')
        : <TableContainer component={Paper} className={classes.table}>
          <Table aria-label="simple table" size="small">
            <TableBody>
              {items.filter(item => !nonVisibleFields.includes(item.label)).map(item => (
                <TableRow key={item.label}>
                  <TableCell component="th" scope="row">
                    {t(`priceListDetailsItem_${item.label}`)}
                  </TableCell>
                  <TableCell align="left">{item.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> 
      }
    </Box>
  )
}
