import { FileAttachment } from './API';

export function sortFilesByDescription(files: FileAttachment[]): FileAttachment[] {
  return files.sort((a, b) => {
    const regex = /^\d+/;

    const descriptionA = a.description || '';
    const descriptionB = b.description || '';

    if (!descriptionA && !descriptionB) return 0;
    if (!descriptionA) return 1;
    if (!descriptionB) return -1;

    const numA = descriptionA.match(regex);
    const numB = descriptionB.match(regex);

    if (numA && numB) {
      return parseInt(numA[0], 10) - parseInt(numB[0], 10);
    }
    if (numA) return -1;
    if (numB) return 1;

    return descriptionA.localeCompare(descriptionB);
  });
}