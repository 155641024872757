import { Box, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { FolderOutlined, Group, LinkOutlined, PlaceOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import * as navigator from '../tools/Navigator'
import { isDevEnv, isLocalEnv, isStagingEnv } from '../tools/Utils'

const useStyles = makeStyles(theme => (
  {
    toolbar: theme.mixins.toolbar,
    drawerTitle: {
      flexGrow: 1,
      textAlign: 'left',
      paddingLeft: "1rem"
    }
  }
))

/**
 * Drawer menu contents element.
 */
export function DrawerMenu() {
  const classes = useStyles()
  const { t } = useTranslation()

  // Use a 100% tall Grid so that the optional env + version item can be aligned to bottom
  return (
    <Grid container direction='column' justifyContent='space-between' style={{height: '100%'}}>
      <Grid item>
        <Box
          className={classes.toolbar}
          display='flex'
          alignItems='center'
        >
          <Typography variant="h6" className={classes.drawerTitle}>{t('drawerTitle')}</Typography>
        </Box>
        <Divider/>
        <List>
          <ListItem
            button
            key='itemSites'
            onClick={() => navigator.push(navigator.PATH_SITES)}
            >
            <ListItemIcon><PlaceOutlined/></ListItemIcon>
            <ListItemText primary={t('drawerMenuSites')}/>
          </ListItem>
          <ListItem
            button
            key='itemCustomers'
            onClick={() => navigator.push(navigator.PATH_CUSTOMERS)}
            >
            <ListItemIcon><Group/></ListItemIcon>
            <ListItemText primary={t('drawerMenuCustomers')} />
          </ListItem>
          <ListItem
            button
            key='itemContracts'
            onClick={() => navigator.push(navigator.PATH_CONTRACTLINES)}
            >
            <ListItemIcon><FolderOutlined/></ListItemIcon>
            <ListItemText primary={t('drawerMenuContracts')} />
          </ListItem>
          <Box py={1}>
            <Divider variant="middle" light/>
          </Box>
          <ListItem
            button
            key='itemLaadunseuranta'
            onClick={() => navigator.navigateToExternalSite(navigator.getLaadunseurantaLink('/'))}
            >
            <ListItemIcon><LinkOutlined/></ListItemIcon>
            <ListItemText primary={t('drawerMenuLaadunseuranta')} />
          </ListItem>
        </List>
      </Grid>
      { envText &&
      <Grid item>
        <Divider/>
        <Box display='flex' justifyContent='center'>
          <Typography variant='overline' align='center'>{`${envText} v${process.env.REACT_APP_VERSION}`}</Typography>
        </Box>
      </Grid>
      }
    </Grid>
  )
}

const envText = getEnvText()

function getEnvText() {
  if (isStagingEnv) {
    return 'test'
  } else if (isDevEnv) {
    return 'dev'
  } else if (isLocalEnv) {
    return 'local'
  } else {
    return undefined
  }
}
