import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { ArrowForward, LibraryBooksOutlined } from '@material-ui/icons'
import { useSnackbar } from 'notistack'

import * as api from '../tools/API'
import * as navigator from '../tools/Navigator'
import * as snacky from './CustomSnackbarProvider'
import { Contract, Site } from '../tools/API'
import { getErrorMessage, isDefined, isEmpty } from '../tools/Utils'
import { DetailsCard } from './DetailsCard'

const useListStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    '& .MuiListItem-root': {
      cursor: 'pointer'
    }
  }
}));

export function SitePriceListCard({site}: {site?: Site}) {
  const { t } = useTranslation()
  const classes = useListStyles();
  const { enqueueSnackbar } = useSnackbar()
  const [inProgress, setInProgress] = useState(false)
  const [contracts, setContracts] = useState<Contract[]>()

  async function fetchContracts() {
    try {
      setInProgress(true)
      const contractsResp = await api.listContractsBySiteId(site!.id)
      setInProgress(false)
      setContracts(contractsResp)
    } catch (err) {
      console.log(`Error fetching: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchGeneric', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
  }

  function getSubTitle() {
    if (inProgress) {
      return t('sitePriceListLoading')
    }
    if (isEmpty(contracts)) {
      return t('sitePriceListCannotBeLoaded')
    }
    return undefined
  }

  return (
    <DetailsCard
      cardId='sitePriceList'
      title={t('sitePriceListTitle')}
      subtitle={getSubTitle()}
      icon={<LibraryBooksOutlined/>}
      noPadContent={true}
      onInit={isDefined(site) ? fetchContracts : undefined}
    >
      {isDefined(contracts) && <List className={classes.root}>{
        contracts.length > 0
          ? contracts.map(contract => (<SiteContractNumber contract={contract} site={site} key={contract.id} />))
          : t('siteHasNoContracts')
      }</List>}
    </DetailsCard>
  )
}

interface SiteContractNumberProp {
  contract: Contract
  site?: Site
}

export function SiteContractNumber(prop: SiteContractNumberProp) {
  const { t } = useTranslation()

  return (
    <ListItem onClick={() => navigator.push(`/contract/${prop.contract.id}/price-list/tab-0`, {contractId: prop.contract.id, site: prop.site})}>
      <ListItemText>{t('sitePriceListForContractLabel', {contractId: prop.contract.externalId})}</ListItemText>
      <ListItemSecondaryAction><ArrowForward/></ListItemSecondaryAction>
    </ListItem>
  )
}
