import { TFunction, useTranslation } from 'react-i18next'

import * as api from '../tools/API'
import { formatAsLocalDate } from '../tools/Strings'
import { BaseTable, ColumnDef } from './BaseTable'
import { isDefined } from '../tools/Utils'

const columns: ColumnDef[] = [
  { width: 12, field: 'productNumber', headerTextId: 'priceListLineHeaderProductNumber' },
  { width: 34, field: 'productName', headerTextId: 'priceListLineHeaderProductName' },
  { width: 10, field: 'unitPrice', headerTextId: 'priceListLineHeaderUnitPrice' },
  { width: 8, field: 'unit', headerTextId: 'priceListLineHeaderUnit' },
  { width: 12, field: 'validFrom', headerTextId: 'priceListLineHeaderValidFrom' },
  { width: 12, field: 'validTo', headerTextId: 'priceListLineHeaderValidTo' }
]

function mapValue(t: TFunction<"translation">, field: string, value: any) {
  if (field === 'validFrom' || field === 'validTo') {
    return formatAsLocalDate(value)
  } else if (field === 'unitPrice') {
    return t('genericDecimalNumber', {value: value})
  }

  return value
}

interface PriceListLinesTableProp {
  contractId: number,
  onExport: (args: api.SearchArgs, extraArgs?: any) => Promise<void>
}

export function PriceListLinesTable(prop: PriceListLinesTableProp) {
  const { t } = useTranslation()

  async function loadPriceListLinesAsPageResult(args: api.SearchArgs) {
    const result = await api.getContractPriceListById(prop.contractId, args.searchTerm)
    return {
      results: isDefined(result) ? result.lines : [],
      page: 0,
      totalResults: isDefined(result) ? result.lines.length : 0
    }
  }

  return (
    <>
      <BaseTable
        tableId='priceListLines'
        columns={columns}
        filterLabelId={t('priceListSearchLabel')}
        onFetchItems={loadPriceListLinesAsPageResult}
        extraArgs={{ contractId: prop.contractId}}
        onExport={prop.onExport}
        valueMapper={(field, value) => mapValue(t, field, value)}
      />
    </>
  )
}
