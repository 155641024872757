import { Link, PeopleAltOutlined } from "@material-ui/icons"
import { DetailsButton, DetailsCard } from "./DetailsCard"
import { LabeledItem, LabeledItemGroup } from "./LabeledItem"
import { useTranslation } from 'react-i18next'
import { Opt } from '../tools/Utils'
import { Customer } from '../tools/API'
import * as navigator from '../tools/Navigator'

export interface CustomerInfoCardProp {
  customer: Opt<Customer>
  showLink?: boolean
}

export function CustomerInfoCard(p: CustomerInfoCardProp) {
  const { t } = useTranslation()

  return (
    <DetailsCard
      cardId='siteCustomer'
      title={t('siteCustomerTitle')}
      icon={<PeopleAltOutlined/>}
      auxButton={p.showLink && p.customer ? <CustomerLinkButton customer={p.customer}/> : undefined}
    >
      { p.customer &&
      <LabeledItemGroup>
        <LabeledItem label={t('siteCustomerLabelExtId')} value={p.customer.externalId.toString()} />
        <LabeledItem label={t('siteCustomerLabelName')} value={p.customer.name} />
      </LabeledItemGroup>
      }
    </DetailsCard>
  )
}

function CustomerLinkButton({customer}: {customer: Customer}) {
  return (
    <DetailsButton
      key='customer-link'
      tooltipKey='siteCustomerLinkTooltip'
      icon={<Link/>}
      onClick={() => navigator.openCustomerDetails(customer)}
    />
  )
}
