import { Box, Button, Card, CardActions, CardContent, Container, CssBaseline, Grid, IconButton, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSnackbar } from 'notistack'
import { Visibility, VisibilityOff } from "@material-ui/icons"

import * as navigator from '../tools/Navigator'
import * as http from '../tools/Http'
import * as aad from '../tools/AzureAD'
import { getErrorMessage, isBlankString } from "../tools/Utils"
import * as snacky from './CustomSnackbarProvider'

const useStyles = makeStyles(theme => (
  {
    root: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      flexGrow: 1
    },
    loginForm: {
      backgroundColor: 'white'
    },
    aadSignInButton: {
      textTransform: 'none',
      width: '100%'
    },
    loginDividerLabel: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      textAlign: 'center'
    }
  }
))

export interface SigningInViewProp {
  forceSignIn?: boolean
}

export function SignInView(p: SigningInViewProp) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [requestActive, setRequestActive] = useState(false);

  const isLoginDisabled = () => requestActive || isBlankString(username) || isBlankString(password)

  const errorReasonByMessage = new Map([
    ['bad credentials', t('signInErrInvalidCredentials')],
    ['wrong credentials', t('signInErrInvalidCredentials')],
    ['user credentials have expired', t('signInErrCredentialsExpired')],
    ['user account has expired', t('signInErrAccountExpired')],
    ['user account is locked', t('signInErrAccountLocked')],
    ['user is disabled', t('signInErrAccountDisabled')],
    ['invalid user information', t('signInErrInvalidUserInfo')],
    ['invalid role', t('signInErrInvalidRole')],
    ['unknown azure ad user', t('signInErrUnknownAadUser')],
    ['interaction_in_progress', t('signInErrAadInteractionInProgress')],
    ['user_cancelled', t('signInErrUserCancelled')],
  ]);

  async function login() {
    if (!username && !password) {
      return
    }

    try {
      setRequestActive(true)
      const auth = await http.login(username, password)
      if (!(auth.roles.includes('ADMIN') || auth.roles.includes('SITE_MANAGER'))) {
        throw invalidRoleException()
      }
      setRequestActive(false)
      navigator.replaceWithNextOr('/')
    } catch (err) {
      console.log(`Login failed: ${JSON.stringify(err)}`)
      setRequestActive(false)
      setError(errorReasonByMessage.get(err.data?.message?.toLowerCase()) ?? 'signInErrGeneric')
    }
  }

  async function aadLogin() {
    try {
      setRequestActive(true)
      if ((p.forceSignIn ?? false) || !http.areCredentialsDefined()) {
        await aad.signIn()
      }
      await http.updateAuthenticatedParty()
      setRequestActive(false)
      navigator.replaceWithNextOr('/')

    } catch (err) {
      console.log(`Error signing in: ${JSON.stringify(err)}`)
      setRequestActive(false)
      enqueueSnackbar(t('errSignIn', {message: getAadErrorMessage(err)}), snacky.errorOpts)
    }
  }

  interface ErrorWithData {
    data: {
      message: string
    }
  }

  interface AuthError {
    errorCode: string
    errorMessage: string
    subError?: string
    name: string
    correlationId: string
  }

  function getAadErrorMessage(err: Error | ErrorWithData | AuthError) {
    if ('data' in err) {
      return errorReasonByMessage.get(err.data.message?.toLowerCase()) ?? t('errSignIn', {message: getErrorMessage(err)})
    } else if ('errorCode' in err) {
      return errorReasonByMessage.get(err.errorCode.toLowerCase()) ?? t('errSignIn', {message: getErrorMessage(err)})
    } else if (err instanceof Error) {
      return getErrorMessage(err)
    }
    return t('signInErrGeneric')
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container maxWidth='xs'>
        <Card className={classes.loginForm}>
          <CardContent>
            <Grid container direction='column' alignItems='stretch' spacing={3}>
              <Grid item>
                <Typography variant='h6' className={classes.title}>{t('signInCardTitle')}</Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={aadLogin}
                  disabled={requestActive}
                  className={classes.aadSignInButton}
                  variant='contained'
                  color='primary'
                >
                  {t('signInWithAadButtonLabel')}
                </Button>

              </Grid>
              <Grid item className={classes.loginDividerLabel}>{t('signInDividerLabel')}</Grid>
              <Grid item>
                <TextField
                id={'loginUsername'}
                  fullWidth
                  name='username'
                  label={t('signInUsernameLabel')}
                  variant='outlined'
                  onChange={event => setUsername(event.target.value)}
                  required={true}
                  error={error !== undefined}
                  type='text'
                  autoFocus={true}
                  autoComplete='username'
                />
              </Grid>
              <Grid item>
                <TextField
                  id={'loginPassword'}
                  fullWidth
                  name='password'
                  label={t('signInPasswordLabel')}
                  variant='outlined'
                  onChange={event => setPassword(event.target.value)}
                  onKeyPress={event => event.key === 'Enter' && !isLoginDisabled() && login()}
                  required={true}
                  error={error !== undefined}
                  type={showPassword ? 'text' : 'password'}
                  helperText={error !== undefined ? t(error) : null}
                  autoComplete='password'
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={event => event.preventDefault()}
                      >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Box px={1} pb={1} width='100%'>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <Button onClick={login} disabled={isLoginDisabled()} variant='contained' color='primary'>{t('signInButtonLabel')}</Button>
                </Grid>
              </Grid>
            </Box>
          </CardActions>
        </Card>
      </Container>
    </div>
  )
}

interface InvalidRoleException {
  data: {
    message: string
  }
}

function invalidRoleException(): InvalidRoleException {
  return { data: { message: 'Invalid role' }};
}
