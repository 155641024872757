import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Switch, Typography } from '@material-ui/core'
import { VisibilityOff, VisibilityOutlined } from '@material-ui/icons'
import { useSnackbar } from 'notistack'

import * as api from '../tools/API'
import { Site } from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import { getErrorMessage } from '../tools/Utils'
import { DetailsCard } from './DetailsCard'

export function SiteStatusCard({site}: {site?: Site}) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [isPublished, setPublished] = useState<boolean | undefined>(undefined);
  const [isInProgress, setInProgress] = useState(false);
  const cardIcon = isPublished === undefined ? null : (
      isPublished === true ? <VisibilityOutlined/> : <VisibilityOff/>
    );
  const cardSubtitle = isPublished === undefined ? '' : (
      isPublished === true ? t('siteStatusLabelPublished') : t('siteStatusLabelNotPublished')
    );

  useEffect(() => { setPublished(site?.isPublished) }, [site?.isPublished])

  async function switchPublishingStatus() {
    try {
      setInProgress(true);
      const response = await api.updateSitePublishingStatus(site!.id, {isPublished: !isPublished})
      setPublished(response.isPublished)
    } catch (err) {
      console.log(`Failed to save publishing status: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errSave', {message: getErrorMessage(err)}), snacky.errorOpts)
    } finally {
      setInProgress(false);
    }
  }

  return (
    <DetailsCard cardId='siteStatus' title={t('siteStatusTitle')} subtitle={cardSubtitle} icon={cardIcon}>
      { site &&
      <Grid container direction='column' spacing={1}>
        <Box display='flex' flexDirection='row' width='100%'>
          <Box flexGrow={1}>
            {<Typography variant='subtitle1'>{t('siteStatusTitle')}</Typography>}
          </Box>
          <Switch
            edge='end'
            disabled={isInProgress}
            checked={isPublished === true}
            onChange={switchPublishingStatus}
            color='primary'
          />
        </Box>
      </Grid>
      }
    </DetailsCard>
  )
}

