import * as utils from './Utils'
import * as aad from './AzureAD'
import { Opt } from './Utils'
import { Party } from './API'

const KEY_USERNAME = 'iss-username'
const KEY_PASSWORD = 'iss-password'

const USER_AGENT = `${process.env.REACT_APP_NAME}/${process.env.REACT_APP_VERSION} - ${navigator.userAgent}`
const HEADER_AUTHORIZATION = 'Authorization'

export interface AuthenticatedParty extends Party {
  // These are helper definitions set during authentication:
  isAdmin?: boolean,
  isExtAdmin?: boolean,
  isSuperUser?: boolean,
  isOrgAdmin?: boolean,
  isExtUser?: boolean,
  isExtTempUser?: boolean,
  isSiteManager?: boolean,
}

export type MessageCallback = (message: Opt<string>) => void
export type QueryParameters = Record<string, any>

export enum HttpMethod {
  GET,
  POST,
  PUT,
  DELETE,
  HEAD
}

export interface RequestArgs {
  qp?: QueryParameters
  headers?: Headers
  body?: any // This gets converted to a JSON string if content-type is application/json (default).
  request?: RequestInit
  authorize?: boolean
}

let authenticatedParty: Opt<AuthenticatedParty> = undefined
let onAuthenticationError: Opt<MessageCallback> = undefined

let acceptLanguage: string | undefined = undefined

const pendingGets: Map<string, Promise<any>> = new Map();
/**
 * This header affects what language the backend uses for translating error messages.
 */
export function setAcceptLanguage(lang?: string) {
  acceptLanguage = lang
}

/** Ignore null and undefined values. */
function excludeValueless(qp: QueryParameters): QueryParameters {
  const qps: QueryParameters = {}

  for (const name in qp) {
    const value = qp[name]

    if (value !== undefined) {
      qps[name] = value
    }
  }

  return qps
}

function getURL(path: string, qp?: QueryParameters): string {
  const BASE_URL = '/api/v1'
  let uri = `${BASE_URL}${path.startsWith('/') ? '' : '/'}${path}`

  if (qp) {
    qp = excludeValueless(qp)
    let qpString = new URLSearchParams(qp).toString()

    if (uri.indexOf('?') === -1) {
      uri += `?${qpString}`
    } else {
      uri += `&${qpString}`
    }
  }

  return uri
}

async function getHeaders(copyHeaders?: Headers, authorize?: boolean, username?: Opt<string>, password?: Opt<string>): Promise<Headers> {
  let headers = new Headers()
  headers.set('User-Agent', USER_AGENT)

  if (authorize) {
    username = username || getUsername()
    password = password || getPassword()

    if (!!username && !!password) {
      const credentials = btoa(`${username}:${password}`)
      headers.set(HEADER_AUTHORIZATION, `Basic ${credentials}`)

    } else if (aad.isSignedIn()) {
      const token = await aad.acquireToken()
      headers.set(HEADER_AUTHORIZATION, `Bearer ${token}`)
    }
  }

  if (acceptLanguage) {
    headers.set('Accept-Language', acceptLanguage)
  }

  if (copyHeaders) {
    for (const [name, value] of copyHeaders) {
      headers.set(name, value)
    }
  }

  return headers
}

async function getRequest(method: HttpMethod, path: string, args?: RequestArgs) {
  const url = getURL(path, args?.qp)

  let req = args?.request || {}
  req.method = HttpMethod[method]
  req.headers = await getHeaders(args?.headers, args?.authorize ?? true)
  const body = args?.body

  if (body !== undefined) {
    let contentType = req.headers?.get('Content-Type')

    if (!contentType) {

      if (body instanceof FormData) {
        // Content type is set automatically (multipart/form-data; boundary=....)
      } else {
        contentType = 'application/json'
        req.headers.set('Content-Type', contentType)
      }
    }

    if (typeof body === 'string') {
      // stringify() would otherwise add quotes - which probably is as it should.
      // However, the backend accepts JSON content type, but saves the string as is - with the quotes.
      req.body = body
    } else if (contentType === 'application/json') {
      req.body = JSON.stringify(body)
    } else {
      req.body = body
    }
  }

  return new Request(url, req)
}

async function forceUpdateToken(request: Request) {
  try {
    const token = await aad.acquireToken(null, true)
    request.headers.set(HEADER_AUTHORIZATION, `Bearer ${token}`)
    return true
  } catch (err) {
    console.log(`Failed to update token: ${err}`)
    return false
  }
}

async function sendRequest(request: Request, requiredContentType?: string, responseReader?: (response: Response) => any): Promise<any> {
  const url = request.url
  const method = request.method

  console.log(`SEND ${method} ${url}`)
  const response = await fetch(request)
  const contentType = response.headers.get('content-type');
  console.log(`RECV ${method} ${url} - ${response.status} ${contentType || ''}`)
  let data: any = undefined

  if (response.status === 204 || !contentType || (requiredContentType && !contentType.startsWith(requiredContentType))) {
    // No content
    data = undefined
  } else if (responseReader) {
    data = await responseReader(response)
  }

  // When authentication fails, automatically clear stored credentials and notify
  // App (via onAuthenticationError) so that it can take care of navigating to login view.
  // Alternative would be to do nothing - it could be useful if the user has produced something
  // that could not be saved (due to auth err), but then again the user would have to enter
  // the credentials (login) in order to retry saving. If the data in the forms would be important
  // enough, then perhaps each form could persist its state in local storage.
  if (response.status === 401 && areCredentialsDefined()) {

    if (aad.isSignedIn() && (await forceUpdateToken(request))) {
      console.log(`Refreshed token successfully - retry`)
      return sendRequest(request, requiredContentType, responseReader)
    }

    // Don't call logout() as in AAD case it would be visible for the user.
    clearCredentials()
    if (onAuthenticationError) onAuthenticationError(data.message)
  }

  if (!response.ok) {

    if (!data) {
      data = {}
    }

    throw new RequestError(response, data)
  }

  return data
}

async function makeJsonRequest(method: HttpMethod, path: string, args?: RequestArgs) {
  const request = await getRequest(method, path, args)
  return await sendRequest(request, 'application/json', response => response.json())
}

export async function getPdf(path: string, args?: RequestArgs) {
  const request = await getRequest(HttpMethod.GET, path, args)
  return await sendRequest(request, 'application/pdf', response => response.blob())
}

export async function getBlob(path: string, args?: RequestArgs) {
  const request = await getRequest(HttpMethod.GET, path, args)
  return await sendRequest(request, undefined, response => response.blob())
}

export async function getJson(path: string, args?: RequestArgs) {
  const existing = pendingGets.get(path)
  if (existing) {
    console.log(`Returning promise for exising GET request instead of making a new one (${path})`)
    return existing
  }
  const req = makeJsonRequest(HttpMethod.GET, path, args)
    .finally(() => pendingGets.delete(path))
  pendingGets.set(path, req)
  return req
}

export async function putJson(path: string, body: any, args?: RequestArgs) {
  args = args || {}
  args.body = body
  return makeJsonRequest(HttpMethod.PUT, path, args)
}

export async function postJson(path: string, body: any, args?: RequestArgs) {
  args = args || {}
  args.body = body
  return makeJsonRequest(HttpMethod.POST, path, args)
}

export async function deleteJson(path: string, args?: RequestArgs) {
  return makeJsonRequest(HttpMethod.DELETE, path, args)
}

function getUsername(): Opt<string> {
  return localStorage.getItem(KEY_USERNAME)
}

function getPassword(): Opt<string> {
  return localStorage.getItem(KEY_PASSWORD)
}

export function updatePassword(newPassword: string) {
  localStorage.setItem(KEY_PASSWORD, newPassword)
}

export async function login(username: string, password: string): Promise<AuthenticatedParty> {
  await updateAuthenticatedParty(username, password)
  localStorage.setItem(KEY_USERNAME, username)
  localStorage.setItem(KEY_PASSWORD, password)
  return authenticatedParty!
}

function clearCredentials() {
  authenticatedParty = undefined
  localStorage.removeItem(KEY_USERNAME)
  localStorage.removeItem(KEY_PASSWORD)
}

export async function logout() {
  clearCredentials()

  if (aad.isSignedIn()) {
    aad.signOut()
    // Don't wait for the promise returned by signOut() to complete because otherwise the redirect
    // would only happen once user has closed the signout-popup (seeing the current view in
    // the background behind the "you are now signed out" popup looks a bit strange).
  }
}

export async function updateAuthenticatedParty(username?: string, password?: string): Promise<AuthenticatedParty> {
  let headers: Opt<Headers> = undefined

  // username and password args allow using this function to login a user without
  // having to persist credentials before we know they are valid.
  if (username && password) {
    headers = await getHeaders(undefined, true, username, password)
  }

  try {
    let party = await getJson('/authenticated-party', {headers: headers}) as AuthenticatedParty

    if (!party) {
      throw new Error('Failed to get authenticated party')
    }

    const roles = party.roles
    party.isExtAdmin = roles.includes('EXT_ADMIN')
    party.isAdmin = roles.includes('ADMIN')
    party.isSuperUser = party.isAdmin && party.isExtAdmin
    party.isOrgAdmin = party.isExtAdmin && !party.isSuperUser
    party.isExtUser = roles.includes('EXT_USER')
    party.isExtTempUser = roles.includes('EXT_TEMPORARY_USER')
    party.isSiteManager = roles.includes('SITE_MANAGER')

    authenticatedParty = party
    return party

  } catch (err) {
    console.log(`Failed to get information about authenticated party - logout`)
    logout()
    throw err
  }
}

export function currentAuthenticatedParty(): Opt<AuthenticatedParty> {
  return authenticatedParty
}

export function currentAccountName() {
  if (aad.isSignedIn()) {
    const acc = aad.getAccount()
    return acc?.name ?? acc?.username
  } else {
    return authenticatedParty?.username
  }
}

export function areCredentialsDefined(): boolean {
  return aad.isSignedIn() || areBasicCredentialsDefined()
}

function areBasicCredentialsDefined() {
  return !utils.isBlankString(getUsername()) && !utils.isBlankString(getPassword())
}

export function setOnAuthenticationError(handler?: MessageCallback) {
  onAuthenticationError = handler
}

class RequestError extends Error {

  status: number
  data?: any

  constructor(response: Response, data?: any) {
    const msg = getRequestErrorMessage(response, data) ?? 'Request failed'
    super(msg)
    this.status = response.status
    this.data = data
    this.message = msg
  }
}

function getRequestErrorMessage(response: Response, data?: any): Opt<string> {
  let msg = data?.message

  if (!msg || msg === 'No message available') {
    msg = data?.error
  }

  if (!msg) {
    msg = `${response.status}`
  }

  return msg
}
