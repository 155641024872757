import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export interface ConfirmDialogProp {
  open: boolean
  title?: string
  text?: string
  onAction: (yes?: boolean) => void
  positiveLabel?: string
  negativeLabel?: string
  disabled?: boolean
}

export function ConfirmDialog(p: ConfirmDialogProp) {
  const { t } = useTranslation()
  const disabled = p.disabled ?? false

  return (
    <Dialog
      open={p.open}
      onClose={() => p.onAction(undefined)}
    >
      {p.title && <DialogTitle id='alert-dialog-title'>{p.title}</DialogTitle>}
      <DialogContent>
        {p.text && <DialogContentText id='alert-dialog-description'>{p.text}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => p.onAction(false)} color='primary' disabled={disabled} autoFocus>
          {p.negativeLabel ?? t('buttonNo')}
        </Button>
        <Button onClick={() => p.onAction(true)} color='primary' disabled={disabled}>
          {p.positiveLabel ?? t('buttonYes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
