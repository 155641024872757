import { DateTime } from 'luxon'
import { TFunction } from 'i18next'

import * as http from './Http'
import { Opt, LocalDate, isDefined, defaultTo } from './Utils'
import { LocalizedValue } from './Strings'
import { TeamCardType } from '../components/TeamCard'

// This should match with upload file size limit in backend.
export const MAX_UPLOAD_FILE_SIZE = 140*1024*1024

export interface Idful {
  id: number
}

export interface RestResult {
  id?: number
  status?: string
  message?: string
  // TODO validationErrors
}

export interface Party extends Idful {
  roles: string[],
  activeAccount: boolean,
  externalId?: number,
  username?: string,
  firstname?: string,
  lastname?: string,
  email?: string,
  phoneNumber?: string,
  jobTitle?: string,
}

export interface Customer extends Idful {
  externalId: number
  name: string
  isActive: boolean
  files: FileAttachment[]
}

export type FileAttachmentType = 'SERVICE_DESCRIPTION' | 'CUSTOMER' | 'SITE'

export interface FileAttachment {
  id: number
  type: FileAttachmentType
  filename: string
  mediaType: string
  description: string
  added: string
  isActive: boolean
  category?: string
  publicUid?: string
  targetId?: number
}

export type SiteSize = 'UNDER_500' | 'FROM_500_TO_1200' | 'OVER_1200'

export interface SiteType extends Idful {
  name: string
}

export interface SiteGroup extends Idful {
  name: string
}

export interface Site extends Idful {
  externalId?: number
  name: string
  description?: string
  streetAddress: string
  postCode: string
  city: string
  isActive: boolean
  size: SiteSize
  type?: SiteType
  siteGroup?: SiteGroup
  evaluationParties?: Party[]
  files: FileAttachment[]
  customer?: Customer,
  isPublished: boolean,
  isConfidential: boolean,
  contracts: Contract[]
}

export interface SitePublishingCommand {
  isPublished: boolean
}

export interface SitePublishingResponse extends Idful {
  isPublished: boolean,
}

export interface PageResult<T> {
  results: T[]
  page: number
  totalResults: number
  lastMatch?: (number|string)[]
}

export type SortOrder = 'ASC' | 'DESC'

export interface SearchArgs {
  searchTerm?: string
  /** Page number for legacy style paging. */
  page?: number
  /** Max number of results to fetch */
  size?: number
  /**
   * Reference to last returned match in the index. Will be always present with Elasticsearch
   * backed search endpoints after backend drops support for legacy paging.
   */
  lastMatch?: (number|string)[]
  /** Field to use for sorting results. Available for all search endpoints in backend. */
  sortBy?: string
  /** Direction of sorting. Available for all search endpoints in backend. */
  ascending?: boolean
}

export function log(error: string, stack: string | undefined) {
  return http.postJson('/log', {error, stack})
}

export function searchSites(args?: SearchArgs, customerId?: number): Promise<PageResult<Site>> {
  return searchWithCount<Site>('/site/search', args, {
    'customerId': customerId,
    'onlyPublished': false
  })
}

export function searchCustomers(args?: SearchArgs): Promise<PageResult<Customer>> {
  return searchWithCount<Customer>('/customers/search', args)
}

export function searchContractLines(args?: SearchArgs, extraArgs?: {contractNumberPrefix?: number}): Promise<PageResult<ContractLine>> {
  return searchWithCount<ContractLine>('/contract-lines/search', args, {'onlyActive': false, ...extraArgs})
}

export function exportContractLines(args?: SearchArgs, extraArgs?: {contractNumberPrefix?: number}): Promise<Blob> {
  const qps = Object.assign(toQueryParameters(args), {'onlyActive': false, ...extraArgs})
  return http.getBlob(`/contract-lines/export`, { qp: qps })
}

export function reportContractLineError(lineId: number, command: {description: string}): Promise<void> {
  return http.putJson(`/contract-line/${lineId}/report-error`, command)
}

export function getSiteBasicInfoById(siteId: number): Promise<Site> {
  return http.getJson(`/site/${siteId}/basic`)
}

export function searchParties(args?: SearchArgs, includeEmailOnlyUsers?: boolean, hasExternalId?: boolean): Promise<PageResult<Party>> {
  return searchWithCount<Party>('/party/search', args, {
    'includeEmailOnlyUsers': includeEmailOnlyUsers,
    'hasExternalId': hasExternalId
  })
}

export function searchWithCount<T>(path: string, args?: SearchArgs, extraQps?: http.QueryParameters): Promise<PageResult<T>> {
  const qps = Object.assign(toQueryParameters(args), extraQps ?? {})
  return http.getJson(path, { qp: qps })
}

function toQueryParameters(args?: SearchArgs) {
  const params = {
    'searchTerm': args?.searchTerm,
    'page': args?.page,
    'size': args?.size,
    'sortBy': args?.sortBy,
    'sortOrder': getSortOrder(args?.ascending),
    'resultFormat': 'full'
  }
  return args?.lastMatch ? {...params, 'lastMatch': args?.lastMatch} : params
}

function getSortOrder(ascending?: boolean): Opt<SortOrder> {
  if (ascending === undefined) return undefined
  return ascending ? 'ASC' : 'DESC'
}

export function updateSiteDescription(siteId: number, command: {description: string}): Promise<RestResult> {
  return http.putJson(`/site/${siteId}/description`, command)
}

export function updateSitePublishingStatus(siteId: number, command: {isPublished: boolean}): Promise<SitePublishingResponse> {
  return http.putJson(`/site/${siteId}/published`, command)
}

export enum TeamType {
  Site,
  Customer
}

export interface TeamMember extends Party {
  teamRole?: string,
  isManuallyAdded: boolean,
  isSpecial: boolean
}

export function getParty(id: number): Promise<Party> {
  return http.getJson(`party/${id}`)
}

// Backend defaults to jobTitle if role isn't defined.
export function addTeamMember(type: TeamType, id: number, party: Party, role: Opt<string>): Promise<RestResult> {
  return http.postJson(teamPath(type, id), {
    'partyId': party.id,
    'role': role
  })
}

// In the backend both add & modify use the same impl. so addParty.. alone would suffice, but provide the PUT version for completeness.
export function modifyTeamMember(type: TeamType, id: number, party: Party, role: Opt<string>): Promise<RestResult> {
  return http.putJson(teamPath(type, id), {
    'partyId': party.id,
    'role': role
  })
}

export function removeTeamMember(type: TeamType, id: number, party: Party): Promise<RestResult> {
  return http.deleteJson(`${teamPath(type, id)}/${party.id}`)
}

/**
 * @param id Site.id for SiteTeam and Customer.externalId for CustomerTeam.
 */
export function listTeamMembers(teamType: TeamType, id: number, subType?: TeamCardType): Promise<TeamMember[]> {
  return http.getJson(`${teamPath(teamType, id)}${subType ? teamPathSuffixByCardType[subType] : ''}`)
}

const teamPathSuffixByCardType: { [Key in TeamCardType]: String } = {
  [TeamCardType.ServiceTeam]: '/internal',
  [TeamCardType.SiteOthers]: '/external',
  [TeamCardType.CustomerTeam]: ''
}

function teamPath(type: TeamType, id: number) {
  return `/${type === TeamType.Site ? 'sites' : 'customers'}/${id}/team`
}

export function listResponsibleParties(siteId: number): Promise<TeamMember[]> {
  return http.getJson(`/site/${siteId}/responsible-parties`)
}

export function listPartySites(partyId: number): Promise<Site[]> {
  return http.getJson(`/party/${partyId}/sites`)
}

export function listCustomerOwnParties(id: number): Promise<Party[]> {
  return http.getJson(`/customers/${id}/parties`)
}

export type SiteInfoCardType = 'ATTACHMENTS' | 'CALENDAR_EVENTS' | 'EVALUATION_EVENTS' | 'SERVICE_DESCRIPTIONS' |
                               'SITE_TEAM_INTERNALS' | 'SITE_TEAM_EXTERNALS'

export const SiteInfoCardTypeByTeamCardType: { [Key in TeamCardType]: Opt<SiteInfoCardType> } = {
  [TeamCardType.ServiceTeam]: 'SITE_TEAM_INTERNALS',
  [TeamCardType.SiteOthers]: 'SITE_TEAM_EXTERNALS',
  [TeamCardType.CustomerTeam]: null
}

export type SiteInfoCardCount = { [Key in SiteInfoCardType]: number }

 export function getCountsForSiteInfoCards(siteId: number, cards: SiteInfoCardType[]): Promise<SiteInfoCardCount> {
  return http.getJson(`/site/${siteId}/counts?cards=${cards.join(',')}`)
}

export interface SiteFormField {
  labelId: number
  label: LocalizedValue
  value: LocalizedValue

  /** This field is populated on the client side. */
  info?: LocalizedValue
}

export interface SiteForm {
  id: number
  name: LocalizedValue
  isDefault: boolean
  fields: SiteFormField[]
}

export function getDefaultSiteForm(siteId: number): Promise<SiteForm> {
  return http.getJson(`/site/${siteId}/form/default`)
}

export function setSiteFormValues(siteId: number, formId: number, values: LocalizedValue[]): Promise<void> {
  return http.putJson(`/site/${siteId}/form/${formId}`, values)
}

export function setSiteFormFieldValue(siteId: number, formId: number, labelId: number, value: LocalizedValue): Promise<void> {
  return http.putJson(`/site/${siteId}/form/${formId}/value/${labelId}`, value)
}

export interface Contract {
  id: number
  externalId: number
  isActive: boolean
  scopes: string[],
  site: Site
}

export interface ProfitCenter {
  id: number
  externalId: number
  name: string
  isActive: boolean
}

export interface ContractLine {
  id: number
  lineNumber: number
  name: string
  serviceCode: string
  codeWithName: string
  isActive: boolean
  contract: Contract
  profitCenter: ProfitCenter
  responsibleParty: Party
}

export function listContractsBySiteId(siteId: number): Promise<Contract[]> {
  return http.getJson(`/site/${siteId}/contracts`)
}

export function listContractsLinesBySiteId(siteId: number, args?: SearchArgs): Promise<PageResult<ContractLine>> {
  return http.getJson(`/site/${siteId}/contract-lines`, { qp: {
    'page': args?.page,
    'size': args?.size,
  } })
}

export function deleteFileAttachment(fileId: number, permanently?: boolean): Promise<void> {
  return http.deleteJson(`/file-attachment/${fileId}`, {qp: {
    'permanently': permanently
  }})
}

export function getFileAttachment(fileId: number): Promise<Blob> {
  return http.getBlob(`/file-attachment/${fileId}`)
}

export function updateFileAttachment(fileId: number, description: Opt<string>): Promise<FileAttachment> {
  return http.putJson(`/file-attachment/${fileId}`, {
    'description': description
  })
}

export function addSiteFile(siteId: number, file: File, description: Opt<string>, category: Opt<string>): Promise<FileAttachment> {
  const formData = new FormData()
  if (description) formData.append('fileDescription', description)
  if (category) formData.append('fileCategory', category)
  formData.append('file', file)
  return http.postJson(`/site/${siteId}/file`, formData)
}

export function updateSiteFile(siteId: number, fileId: number, description: Opt<string>, category: Opt<string>): Promise<FileAttachment> {
  return http.putJson(`/site/${siteId}/file/${fileId}`, {
    'description': description,
    'category': category
  })
}

export function getSiteFileWithUid(fileUid: string): Promise<Blob> {
  return http.getBlob(`/site/file-with-link/${fileUid}`)
}

export function getSiteFileDataWithUid(fileUid: string): Promise<FileAttachment> {
  return http.getJson(`/site/file-details-with-link/${fileUid}`)
}

export function generateSiteFileLink(siteId: number, fileId: number): Promise<FileAttachment> {
  return http.putJson(`/site/${siteId}/file/${fileId}/link`, {})
}

export function addCustomerFile(externalId: number, file: File, description: Opt<string>): Promise<FileAttachment> {
  const formData = new FormData()
  if (description) formData.append('fileDescription', description)
  formData.append('file', file)
  return http.postJson(`/customers/${externalId}/file`, formData)
}

export function getCustomerById(customerId: number): Promise<Customer> {
  return http.getJson(`/customers/${customerId}`)
}

export type LogEntryTargetType = 'SITE' | 'SITE_TEAM' | 'CALENDAR_EVENT' | 'SERVICE_DESCRIPTION';
export type LogEntryTargetSubType = 'EXTERNAL_PARTIES' | 'ISS_PARTIES' | 'CALENDAR_EVENTS' | 'SERVICE_DESCRIPTIONS';
export type LogEntryAction = 'CREATE' | 'DELETE' | 'UPDATE' | 'READ' | 'READ_MANY';

export interface AuditLogEntryListing {
  id: number;
  targetId: number;
  targetType: LogEntryTargetType;
  targetSubType: LogEntryTargetSubType;
  actionType: LogEntryAction;
  party: Party;
  timestamp: string;
}

export function listSiteRelatedLogEntries(siteId: number, args?: SearchArgs): Promise<PageResult<AuditLogEntryListing>> {
  return http.getJson(`/site/${siteId}/related-log-entries`, {qp: toQueryParameters(args)});
}

export interface PriceListRaw {
  contractExternalId: number
  affiliationType: string
  customerGroup: string
  priceListName: string
  reference: string
  hasRoundedHoursDenied: boolean
  labourMargin: number
  travelMargin: number
  materialsMargin: number
  defaultMargin: number
  multiplier: number
  vatCode: PriceListVatCode
  hasExtraHoursDenied: boolean
  isActive: boolean
  lines: PriceListLineRaw[]
}

export interface PriceListLineRaw {
  id: number
  productName: string
  productNumber: number
  unit: string
  unitPrice: number
  validFrom: string
  validTo: string
}

export type PriceListVatCode = 'UNSPECIFIED' | 'VAT24' | 'VAT14' | 'VAT10' | 'NO_VAT' | 'INVERTED_VAT'

export async function getContractPriceListById(contractId: number, searchTerm?: string ): Promise<PriceList> {
  const qps = {
    searchTerm: searchTerm,
  }
  return toPriceList(await http.getJson(`/contract/${contractId}/price-list`, { qp: qps }))
}

export interface PriceList extends Omit<PriceListRaw, 'lines'> {
  lines: PriceListLine[]
}

export interface PriceListLine extends Omit<PriceListLineRaw, 'validFrom' | 'validTo'> {
  validFrom: DateTime
  validTo: DateTime
}

function toPriceList(r: PriceListRaw): PriceList {
  return {
    ...r,
    lines: r.lines.map(line => toPriceListLine(line))
  }
}

function toPriceListLine(r: PriceListLineRaw): PriceListLine {
  return {
    ...r,
    validFrom: DateTime.fromISO(r.validFrom, { setZone: true }),
    validTo: DateTime.fromISO(r.validTo, { setZone: true }),
  }
}

export function exportContractPriceList(contractId: number, includeZeroPriced: boolean, searchTerm?: string): Promise<Blob> {
  const qps = {
    searchTerm: searchTerm,
    includeZeroPriced: includeZeroPriced
  }
  return http.getBlob(`/contract/${contractId}/price-list/export`, { qp: qps })
}

export interface ServiceDescriptionBase {
  serviceCode: string
  name: string
  additionalInformation?: string
  inContract?: string
  notInContract?: string
}

export interface ServiceDescription extends ServiceDescriptionBase {
  id: number
  isActive: boolean
  files?: FileAttachment[]
  isTemplate: boolean
}

export interface ServiceDescriptionBrief {
  id: number
  serviceCode: string
  name: string
  isActive: boolean
  isTemplate: boolean
}

export function getServiceDescriptionsBySiteId(siteId: number, includeTemplates?: boolean): Promise<ServiceDescriptionBrief[]> {
  return http.getJson(`/site/${siteId}/service-descriptions`, {qp: {
    'includeTemplates': includeTemplates
  }})
}

export function getServiceDescriptionById(id: number,): Promise<ServiceDescription> {
  return http.getJson(`/service-description/${id}`)
}

export function createServiceDescription(siteId: number, description: ServiceDescriptionBase): Promise<ServiceDescription> {
  return http.postJson(`/site/${siteId}/service-description`, description)
}

export function updateServiceDescriptionById(descriptionId: number, description: ServiceDescriptionBase): Promise<RestResult> {
  return http.putJson(`/service-description/${descriptionId}`, description)
}

export function deleteServiceDescriptionById(descriptionId: number): Promise<void> {
  return http.deleteJson(`/service-description/${descriptionId}`)
}

export function addServiceDescriptionFile(descriptionId: number, file: File, description: Opt<string>): Promise<FileAttachment> {
  const formData = new FormData()
  if (description) formData.append('fileDescription', description)
  formData.append('file', file)
  return http.postJson(`/service-description/${descriptionId}/file`, formData)
}

export interface ProductGroup {
  id: number
  productGroupCode: string
  name: string
  isActive: boolean
}

export function listProductGroups(): Promise<ProductGroup[]> {
  return http.getJson(`/product-group`)
}

export type CalendarEventType = 'EVALUATION_EVENT' | 'CALENDAR_EVENT'

interface CalendarEventRaw {
  // Not specified when creating an event.
  id?: number

  siteId: number
  eventStart: string
  eventEnd: string
  isAllDayEvent: boolean
  subject: string
  location: string
  description?: string
  eventType: CalendarEventType
  isActive: boolean
}

export interface CalendarEvent extends Omit<CalendarEventRaw, 'eventStart' | 'eventEnd'> {
  eventStart: DateTime
  eventEnd: DateTime
}

export interface CalendarEventFormModel extends Omit<CalendarEvent, 'eventStart' | 'eventEnd'> {
  eventDate: LocalDate,
  startTime: string,
  endTime: string
}

export async function listCalendarEventsForSite(siteId: number, from: DateTime, to: DateTime): Promise<CalendarEvent[]> {
  const raws: CalendarEventRaw[] = await http.getJson(`/site/${siteId}/calendar`, {qp: {
    'from': from.toUTC().toISO(),
    'to': to.toUTC().toISO()
  }})

  return raws.map(toCalendarEvent)
}

export async function getCalendarEventById(id: number): Promise<CalendarEvent> {
  return toCalendarEvent(await http.getJson(`/calendar/${id}`))
}

export async function createCalendarEvent(event: CalendarEvent): Promise<CalendarEvent> {
  const created = await http.postJson(`/calendar`, toCalendarEventRaw(event))
  return toCalendarEvent(created)
}

export function updateCalendarEvent(event: CalendarEvent): Promise<RestResult> {
  return http.putJson(`/calendar/${event.id}`, toCalendarEventRaw(event))
}

export function deleteCalendarEventById(id: number): Promise<void> {
  return http.deleteJson(`/calendar/${id}`)
}

function toCalendarEventRaw(e: CalendarEvent) {
  return {
    ...e,
    eventStart: e.eventStart.toUTC().toISO(),
    eventEnd: e.eventEnd.toUTC().toISO()
  }
}

function toCalendarEvent(r: CalendarEventRaw): CalendarEvent {
  return {
    ...r,
    eventStart: DateTime.fromISO(r.eventStart, { setZone: true }),
    eventEnd: DateTime.fromISO(r.eventEnd, { setZone: true })
  }
}

export type SiteEvaluationEventType = 'REGULAR_EVALUATION' | 'TWO_PHASED_EVALUATION'
export type EvaluationEventState = 'CANCELLED' | 'READY_FOR_EVALUATION' | 'READY_FOR_CORRECTIONAL_REMARKS' | 'FINISHED' | 'DELETED' | 'STARTED' | 'ANSWERS_DELETED'

interface SiteEvaluationEventRaw extends Idful {
  siteId: number
  formId: number
  activationId: number
  automaticallyCreated: boolean
  isActivationActive: boolean
  evaluationType: SiteEvaluationEventType
  formName: string
  plannedDate: string
  state: EvaluationEventState
  finishedDate: string
  site: Site
}

export interface SiteEvaluationEvent extends Omit<SiteEvaluationEventRaw, 'plannedDate' | 'finishedDate'> {
  plannedDate: DateTime
  finishedDate: DateTime
}

export async function listSiteEvaluationEventsForSite(siteId: number, from: DateTime, to: DateTime): Promise<SiteEvaluationEvent[]> {
  const raws: SiteEvaluationEventRaw[] = await http.getJson(`/site/${siteId}/evaluation_events`, {qp: {
    'from': from.toUTC().toISO(),
    'to': to.toUTC().toISO()
  }})

  return raws.map(toSiteEvaluationEvent)
}

function toSiteEvaluationEvent(r: SiteEvaluationEventRaw): SiteEvaluationEvent {
  return {
    ...r,
    plannedDate: DateTime.fromISO(r.plannedDate, { setZone: true }),
    finishedDate: DateTime.fromISO(r.finishedDate, { setZone: true })
  }
}

export function getPrettyErrorMessage(error: any, t: TFunction): string {
  let errorCause = t('errUnknownError')
  let prettyError
  if (isDefined(error.data)) {
    switch (error.data.status) {
      case 'insecure_file':
        prettyError = t('errSaveDueToScanFailure')
        break;
      case 'notFound':
        prettyError = t('errNotFound', { object: `${error.data.type} ${error.data.id}` })
        break;
      case 'service unavailable':
        prettyError = t('errServiceNotAvailable')
        break;
      case 'access denied':
        prettyError = t('errAccessDenied')
        break;
    }
  }
  return defaultTo(errorCause, prettyError)
}
