import { useState } from 'react'
import { DateTime } from 'luxon'
import { CalendarEvent } from '../tools/API'
import * as api from '../tools/API'
import { getErrorMessage, blankAsUndefined, Opt, truncateToDate, LocalDate, isDefined, subtitleHandler } from '../tools/Utils'
import { splitToGroups, CalendarEventGroup } from '../tools/CalendarEventGroup'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fade, FormControlLabel, Grid, Typography } from '@material-ui/core'
import { DeleteOutlined, Edit, Today } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { OverlineText, SecondaryText, TextItem, TIButton } from './SmallComponents'
import { DetailsCard } from './DetailsCard'
import * as str from '../tools/Strings'
import * as calEventUtils from '../tools/CalendarEventUtils'
import { ConfirmDialog } from './ConfirmDialog'
import { DateItem } from './DateItem'
import { TimeItem } from './TimeItem'
import { useSnackbar } from 'notistack'
import * as snacky from './CustomSnackbarProvider'
import { useEffect } from 'react'

export interface CalendarEventsCardProp {
  siteId: number
}

export function CalendarEventsCard(p: CalendarEventsCardProp) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [inProgress, setInProgress] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [events, setEvents] = useState<CalendarEvent[]>([])
  const [eventGroups, setEventGroups] = useState<CalendarEventGroup[]>([])
  const [adding, setAdding] = useState(false)

  // Keep a complete, flat list of events stored so that it is easy update it after editing / adding / deleting.
  function handleEventsChanged(newEvents: CalendarEvent[]) {
    newEvents.sort(calEventUtils.compareEvents)
    setEvents(newEvents)
    setEventGroups(splitToGroups(newEvents))
  }

  const range = getEventsRange()

  async function fetchListCalendarEventsForSite() {
    try {
      setInProgress(true)
      const calendarEventsResp:CalendarEvent[] = await api.listCalendarEventsForSite(p.siteId, range.from, range.to)
      handleEventsChanged(calendarEventsResp)
      setInProgress(false)
    } catch (err){
      console.log(`Error fetching: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchGeneric', {message: getErrorMessage(err)}), snacky.errorOpts)
      setInProgress(false)
      setHasError(true)
    }
  }

  function updateEvents(event: CalendarEvent, deleted: boolean) {
    let newEvents = events.filter(e => e.id === undefined || e.id !== event.id)

    if (!deleted) {
      newEvents.push(event)
    }

    handleEventsChanged(newEvents)
  }

  function handleSaved(event: CalendarEvent) {
    updateEvents(event, false)
    setAdding(false)
  }

  function handleDeleted(event: CalendarEvent) {
    updateEvents(event, true)
  }

  return (
    <DetailsCard
      cardId='siteCal'
      title={t('siteCalendarTitle')}
      subtitle={
        subtitleHandler({
          inProgress, 
          hasError, 
          loadingText:t('siteCalendarSubtitleLoading'), 
          subtitleText:t('siteCalendarSubtitle', {count: events.length}), 
          errorMsg:t('errFetchGenericNoDetails')
        })
      }
      icon={<Today/>}
      noPadContent={true}
      onAddClicked={() => setAdding(true)}
      addTooltipKey='caevAddTooltip'
      onInit={isDefined(p.siteId) ? fetchListCalendarEventsForSite : undefined}
    >
      <Box display='flex' flexDirection='column' flexGrow={1}>
        { eventGroups.map(g =>
        <EventGroup
          key={g.spec.key}
          group={g}
          onSaved={handleSaved}
          onDeleted={handleDeleted}
        />
        )}
        { adding &&
        <CalendarEventDialog
          event={emptyCalendarEvent(p.siteId)}
          onCancel={() => setAdding(false)}
          onSaved={handleSaved}
        />
        }
      </Box>
    </DetailsCard>
  )
}

interface EventGroupProp {
  group: CalendarEventGroup
  onSaved: (event: CalendarEvent) => void
  onDeleted: (event: CalendarEvent) => void
}

function EventGroup(p: EventGroupProp) {
  const { t } = useTranslation()

  // TODO evaluation_event type events have an id=0, once ITS-946 is done start using the evaluationEvent.id
  let eeId = 1

  return (
    <div>
      <Box style={{backgroundColor: '#F5F5F5'}} p={2} mb={2}>
        <Typography variant='subtitle2' children={t(p.group.spec.labelId)} />
      </Box>
      {p.group.events.map(event => <CalendarEventItem
        key={event.eventType === 'CALENDAR_EVENT' ? `c-${event.id}` : `ee-${eeId++}`}
        event={event}
        onSaved={p.onSaved}
        onDeleted={p.onDeleted}
      />)}
    </div>
  )
}

interface CalendarEventItemProp {
  event: CalendarEvent
  onSaved: (event: CalendarEvent) => void
  onDeleted: (event: CalendarEvent) => void
}

enum CalendarEventItemState {
  Normal,
  ConfirmingDelete,
  Deleting,
  Editing
}

function CalendarEventItem(p: CalendarEventItemProp) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState(CalendarEventItemState.Normal)
  const [showEditButton, setShowEditButton] = useState(false)
  const isWritable = p.event.eventType === 'CALENDAR_EVENT' && p.event.id !== undefined

  async function handleDelete() {
    try {
      setState(CalendarEventItemState.Deleting)
      await api.deleteCalendarEventById(p.event.id!)
      p.onDeleted(p.event)
    } catch (err) {
      console.log(`Error deleting calendar event: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errRemovingCalendarEvent', {message: getErrorMessage(err)}), snacky.errorOpts)
    } finally {
      setState(CalendarEventItemState.Normal)
    }
  }

  // Don't show the description value as it can consist of multiple lines and/or
  // could contain markdown and thus make it unsuitable for a list item => user can open the edit dialog to view it.
  return (
    <Box display='flex' flexDirection='row' flexGrow={1}
        alignItems='center' flexWrap='nowrap' pb={3} px={2}
        onMouseEnter={(_event) => setShowEditButton(true)}
        onMouseLeave={(_event) => setShowEditButton(false)}
      >
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <OverlineText>{getDateTimeText(p.event)}</OverlineText>
        <Typography variant="subtitle1">{p.event.subject}</Typography>
        <SecondaryText variant="body2">{p.event.location}</SecondaryText>
      </Box>
      <Fade in={showEditButton && isWritable}>
        <Box>
          <TIButton
            onClick={() => setState(CalendarEventItemState.Editing)}
            icon={<Edit/>}
            tooltipKey='caevEditTooltip'
          />
          <TIButton
            edge='end'
            onClick={() => setState(CalendarEventItemState.ConfirmingDelete)}
            icon={<DeleteOutlined/>}
            tooltipKey='caevDeleteTooltip'
          />
        </Box>
      </Fade>
      { (state === CalendarEventItemState.ConfirmingDelete || state === CalendarEventItemState.Deleting) &&
      <ConfirmDialog
        open={true}
        disabled={state === CalendarEventItemState.Deleting}
        title={t('caevConfirmRemoveTitle')}
        text={t('caevConfirmRemoveText', {name: p.event.subject})}
        onAction={(yes) => {
          if (yes) {
            handleDelete()
          } else {
            setState(CalendarEventItemState.Normal)
          }
        }}
      />
      }
      { (state === CalendarEventItemState.Editing) &&
      <CalendarEventDialog
        event={calEventUtils.convertToFormModel(p.event)}
        onCancel={() => setState(CalendarEventItemState.Normal)}
        onSaved={event => {
          setState(CalendarEventItemState.Normal)
          p.onSaved(event)
        }}
      />
      }
    </Box>
  )
}

function getDateTimeText(e: CalendarEvent) {
  return str.formatAsLocalDateTimeInterval(e.eventStart, e.eventEnd, true);
}

function getEventsRange() {
  const now = DateTime.now()
  let from = now.minus({ weeks: 1}).startOf('day')
  let to = now.plus({ months: 12}).endOf('day')
  return { from: from, to: to }
}

interface CalendarEventDialogProp {
  event: api.CalendarEventFormModel
  onCancel: () => void
  onSaved: (event: CalendarEvent) => void
}

function CalendarEventDialog(p: CalendarEventDialogProp) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  /*
    Although the API and backend allow having multi-day events, let's retrict the UI to just
    having single-day events for now.
   */
  const [data, setData] = useState(Object.assign({}, p.event))
  const [startTime, setStartTime] = useState<Opt<string>>(p.event.startTime)
  const [endTime, setEndTime] = useState<Opt<string>>(p.event.endTime)
  const [isAllDayEvent, setAllDayEvent] = useState(p.event.isAllDayEvent)
  const [date, setDate] = useState<Opt<LocalDate>>(p.event.eventDate)
  const [hasBeenLoaded, setHasBeenLoaded] = useState(false)

  const editing = isDefined(p.event.id)
  const [disabled, setDisabled] = useState(false)
  const isDataValid = () => blankAsUndefined(data.subject) !== undefined
    && blankAsUndefined(data.location) !== undefined
    && (
      (!isAllDayEvent && blankAsUndefined(startTime) !== undefined && blankAsUndefined(endTime) !== undefined)
      || isAllDayEvent
    )

  useEffect(() => {
    if (!hasBeenLoaded && editing) {
      fetchEventDetails(p.event.id!)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p.event.id, hasBeenLoaded])

  function handleAllDayChange(isNowAllDay: boolean) {
    if (isNowAllDay) {
      setStartTime(null)
      setEndTime(null)
    }
    setAllDayEvent(isNowAllDay)
  }

  async function handleSave(data: CalendarEvent) {
    try {
      setDisabled(true)
      if (data.id) {
        await api.updateCalendarEvent(data)
      } else {
        data = await api.createCalendarEvent(data)
      }

      p.onSaved(data)
    } catch (err) {
      console.log(`Error saving calendar event: ${err}`)
      enqueueSnackbar(t('errSave', {message: getErrorMessage(err)}), snacky.errorOpts)
    } finally {
      setDisabled(false)
    }
  }

  async function fetchEventDetails(id: number) {
    try {
      setDisabled(true)
      const event = await api.getCalendarEventById(id);
      const formModel = calEventUtils.convertToFormModel(event)
      setData(formModel)
      // Since it is not possible to update the date/time selector default values anyway, skip setting those values.
      // Theoretically the entity might have been updated elsewhere after fetching the list data, though.
    }
    catch (err) {
      console.log(`Error fetching calendar event: ${err}`)
      enqueueSnackbar(t('errFetchCalendarEvent', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
    finally {
      setHasBeenLoaded(true)
      setDisabled(false)
    }
  }

  // TODO For now use native date/time pickers because at this point Material UI v5 is in RC phase and
  // there was some dep that didn't work with that + theme stuff changed, and the picker library for v4
  // is no longer maintained (repo is in read-only mode). So at some point switch to v5 and start using
  // the pickers it provides (those look more Material Designish).

  return (
    <Dialog
      open={true}
      onClose={disabled ? undefined : p.onCancel}
      fullWidth={true}
      maxWidth='sm'
    >
      <DialogTitle>{t(editing ? 'caevDialogTitleEdit' : 'caevDialogTitleAdd')}</DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <TextItem
            autoFocus={true}
            name='subject'
            disabled={disabled}
            label={t('caevLabelSubject')}
            value={data.subject ?? ''}
            maxLength={255}
            onChange={event => setData({...data, subject: event.target.value})}
          />
          <Grid item>
            <Grid container direction='row' spacing={2} justifyContent='flex-start'>
              <DateItem
                date={date}
                disabled={disabled}
                label={t('caevLabelDate')}
                onChanged={setDate}
              />
              <TimeItem
                time={startTime ?? ''}
                disabled={disabled || isAllDayEvent}
                label={t('caevLabelStart')}
                onChanged={time => setStartTime(time)}
               />
              <TimeItem
                time={endTime ?? ''}
                disabled={disabled || isAllDayEvent}
                label={t('caevLabelEnd')}
                onChanged={time => setEndTime(time)}
              />
              <FormControlLabel
                label={t('caevLabelIsAllDayEvent')}
                control={<Checkbox
                  checked={isAllDayEvent === true}
                  onChange={() => handleAllDayChange(!isAllDayEvent)}
                />}
              />
            </Grid>
          </Grid>
          <TextItem
            name='location'
            disabled={disabled}
            label={t('caevLabelLocation')}
            value={data.location ?? ''}
            maxLength={255}
            onChange={event => setData({...data, location: event.target.value})}
          />
          <TextItem
            name='description'
            disabled={disabled}
            label={t('caevLabelDescription')}
            maxLength={1000}
            multiline={true}
            value={data.description ?? ''}
            onChange={event => setData({...data, description: event.target.value})}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={p.onCancel}
          disabled={disabled}
          color='primary'
        >
          {t('buttonCancel')}
        </Button>
        <Button
          onClick={() => handleSave(calEventUtils.convertToEntity(data, date, startTime, endTime, isAllDayEvent))}
          disabled={disabled || !isDataValid()}
          color='primary'
        >
          {t(editing ? 'buttonSave' : 'buttonAdd')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function emptyCalendarEvent(siteId: number): api.CalendarEventFormModel {
  return {
    siteId: siteId,
    eventDate: truncateToDate(DateTime.now()),
    startTime: '',
    endTime: '',
    isAllDayEvent: false,
    subject: '',
    location: '',
    description: undefined,
    eventType: 'CALENDAR_EVENT',
    isActive: true
  }
}
