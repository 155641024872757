import { useState } from 'react'
import { Grid, Typography, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Opt } from '../tools/Utils'
import { ProductGroup, listProductGroups } from '../tools/API'
import { useFetchData } from '../tools/Hooks'
import Autocomplete from '@material-ui/lab/Autocomplete'

export interface ProductGroupSelectorItemProp {
  label: string
  initial: Opt<ProductGroup>
  onChange: (productGroup: Opt<ProductGroup>) => void
  error?: string
  autoFocus?: boolean
  disabled?: boolean
}

export function ProductGroupSelectorItem(prop: ProductGroupSelectorItemProp) {
  // Use null instead of undefined as undefined would make the element initially an uncontrolled one.
  const [selected, setSelected] = useState<Opt<ProductGroup>>(prop.initial ?? null)
  const [productGroups, setProductGroups] = useState<ProductGroup[]>([])
  const { t } = useTranslation()

  useFetchData(listProductGroups, pgs => setProductGroups(pgs.filter(pg => pg.isActive)), [])

  return (
    <Grid item>
      <Autocomplete
        fullWidth
        options={productGroups}
        value={selected}
        disabled={prop.disabled ?? false}
        getOptionLabel={asLabel}
        getOptionSelected={pg => pg.productGroupCode === selected?.productGroupCode}
        // ^ the selected?.id might not be a valid one (e.g. when using from service description editor the id is -1), but the code usually is.
        renderOption={(pg, _state) => (<Typography variant="subtitle1">{asLabel(pg)}</Typography>)}
        onChange={(_event, value) => {
          if (typeof value !== 'string') {
            setSelected(value)
            prop.onChange(value)
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={prop.autoFocus}
            label={prop.label}
            variant='outlined'
            error={prop.error !== undefined}
            helperText={prop.error}
            />
        )}
        noOptionsText={t('noMatchingProductGroups')}
        />
    </Grid>
  )
}

function asLabel(pg: ProductGroup) {
  return `${pg.productGroupCode}\u2002${pg.name}`
}
