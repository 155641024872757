import { useState } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { ArrowForward, ExpandMore, Today } from '@material-ui/icons'
import { Accordion, AccordionSummary, AccordionDetails, Box, Grid, Typography, makeStyles } from '@material-ui/core'
import { SiteEvaluationEvent } from '../tools/API'
import * as api from '../tools/API'
import * as str from '../tools/Strings'
import { getErrorMessage, isDefined, subtitleHandler } from '../tools/Utils'
import { useSnackbar } from 'notistack'
import * as snacky from './CustomSnackbarProvider'
import { DetailsCard } from './DetailsCard'
import { SiteEvaluationEventGroup, splitToGroups } from '../tools/SiteEvaluationEventGroup'
import { OverlineText, SecondaryText, TIButton } from './SmallComponents'
import { getLaadunseurantaLink } from '../tools/Navigator'

export interface SiteEvaluationEventsCardProp {
  siteId: number
}
export function SiteEvaluationEventsCard(p: SiteEvaluationEventsCardProp) {
  const { t } = useTranslation()
  const [events, setEvents] = useState<SiteEvaluationEvent[]>([])
  const [eventGroups, setEventGroups] = useState<SiteEvaluationEventGroup[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const [inProgress, setInProgress] = useState(false)
  const [hasError, setHasError] = useState(false)

  function handleEventsChanged(newEvents: SiteEvaluationEvent[]) {
    newEvents.sort(compareEvents)
    setEvents(newEvents)
    setEventGroups(splitToGroups(newEvents))
  }

  const range = getEventsRange()

  async function fetchSiteEvaluationEventsForSite() {
    try {
      setInProgress(true)
      const siteEvaulationEventsResp:SiteEvaluationEvent[] = await api.listSiteEvaluationEventsForSite(p.siteId, range.from, range.to)
      handleEventsChanged(siteEvaulationEventsResp)
      setInProgress(false)
    } catch (err){
      console.log(`Error fetching: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchGeneric', {message: getErrorMessage(err)}), snacky.errorOpts)
      setHasError(true)
      setInProgress(false)
    }
  }

  return (
    <DetailsCard
      cardId='siteEvalEvent'
      title={t('siteEvaluationEventTitle')}
      subtitle={
        subtitleHandler({
          inProgress, 
          hasError, 
          loadingText:t('siteEvaluationEventLoading'), 
          subtitleText:t('siteEvaluationEventSubtitle', {count: events.length}), 
          errorMsg:t('errFetchGenericNoDetails')
        })
      }
      icon={<Today/>}
      noPadContent={true}
      onInit={isDefined(p.siteId) ? fetchSiteEvaluationEventsForSite : undefined}
    >
      <Box display='flex' flexDirection='column' flexGrow={1}>
        { eventGroups.map(g =>
        <EventGroup
          key={g.spec.key}
          group={g}
        />
        )}
      </Box>
    </DetailsCard>
  )
}

const useStyles = makeStyles(theme => (
  {
    accordion: {
      '&:before': { // Hide the separator line that is only shown between AccordionDetails' children (not on top of the first one).
        display: 'none'
      },
      borderTop: '1px solid #ddd',
      '&:last-child': { // Add margin at the bottom (of a collapsed accordion) to allow rounded corners of the card show correctly.
        marginBottom: '5px'
      },
      '&.MuiAccordion-root.Mui-expanded:last-child': { // Awkward, but was needed to override the square cornered defaults for expanded accordion.
        marginBottom: '5px'
      },
      '&.Mui-expanded': { // Remove default expansion effect of neighboring accordions when one is expanded
        margin: 0
      }
    },
    accordionSummary: {
      marginTop: '0px',
      marginBottom: '0px',
      paddingTop: '2',
      paddingBottom: '2',
      backgroundColor: '#F5F5F5'
    },
    accordionDetails: {
      paddingTop: '16px',
      paddingBottom: '0px'
    }
  }
))

interface EventGroupProp {
  group: SiteEvaluationEventGroup
}

function EventGroup(p: EventGroupProp) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Accordion
      elevation={0} // Otherwise the Accordion would look like a card on top of a card.
      className={classes.accordion}
      square={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        className={classes.accordionSummary}
      >
        <Box display='flex' flexDirection='row' width='100%'>
          <Box flexGrow={1}>
            <Typography variant='subtitle2'>{t(p.group.spec.labelId)}</Typography>
          </Box>
          <Box >
            <SecondaryText>{t('siteEvaluationEventSubtitle', {count: p.group.events.length})}</SecondaryText>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionDetails}
      >
        <Box display='flex' flexDirection='column' flexGrow={1}>
          {p.group.events.map(event => <SiteEvaluationEventItem
            key={event.id}
            event={event}
          />)}
        </Box>
      </AccordionDetails>
    </Accordion>
  )

  interface SiteEvaluationEventItemProp {
    event: SiteEvaluationEvent
  }

  function SiteEvaluationEventItem(p: SiteEvaluationEventItemProp) {
    return (
      <Box display='flex' flexDirection='row' flexGrow={1} alignItems='center' flexWrap='nowrap' pb={3} px={2}>
        <Box display='flex' flexDirection='column' flexGrow={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
          >
            <Grid>
              <OverlineText>{getDateTimeText(p.event)}</OverlineText>
              <Typography variant="subtitle1">{p.event.site.name}</Typography>
              <SecondaryText variant="body2">{p.event.formName}</SecondaryText>
            </Grid>
            <Grid>
              <TIButton
                  href={activationUrl(p.event.activationId)}
                  target="_blank"
                  rel="noopener"
                  icon={<ArrowForward/>}
                  edge="end"
                  tooltipKey="siteEvaluationEventTooltip"
                />
              </Grid>
            </Grid>
        </Box>
      </Box>
    )
  }
}

function getDateTimeText(e: SiteEvaluationEvent) {
  return str.formatAsLocalDateTime(e.plannedDate, true);
}

function activationUrl(activationId: number) {
  return getLaadunseurantaLink(`/activatedEvaluations/${activationId}`);
}

function getEventsRange() {
  const now = DateTime.now()
  let from = now.minus({ months: 6}).startOf('day')
  let to = now.plus({ months: 1}).endOf('day')
  return { from: from, to: to }
}

function compareEvents(a: SiteEvaluationEvent, b: SiteEvaluationEvent) {
  const dStart = a.plannedDate.toMillis() - b.plannedDate.toMillis()
  if (dStart !== 0) {
    return dStart
  }
  const dId = a.id - b.id
  if (dId !== 0) {
    return dId
  }
  return 0
}

